export default [
  {
    name: `place_vendome_2`,
    label: `Découvrez la Place Vendôme`,
  },
  {
    name: `maquettes_etain`,
    label: `Poussez la porte des Ateliers`,
  },
  {
    name: `entree_ateliers`,
    label: `Rencontrez les Mains d'Or`,
  },
  {
    name: `polisseur`,
    label: `Polisseur`,
  },
  {
    name: `joaillier_1`,
    label: `Joailler`,
  },
  {
    name: `joaillier_2`,
    label: `Joailler`,
  },
  {
    name: `sertisseur`,
    label: `Sertisseur`,
  },
  {
    name: `fondeur`,
    label: `Fondeur`,
  },
  {
    name: `diamantaire`,
    label: `Diamantaire`,
  },
  {
    name: `lapidaire_1`,
    label: `Lapidaire`,
  },
  {
    name: `lapidaire_2`,
    label: `Lapidaire`,
  },
  {
    name: `concepteur_cao`,
    label: `Concepteur CAO`,
  },
];