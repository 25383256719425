export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `아틀리에 지도`,
    },
    {
      name: `toggle_fullscreen`,
      text: `전체 화면`,
    },
  ]
}