export default {
  name: `map`,
  floorNames: [
    `1号楼`,
    `2号楼`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `镶嵌师`,
    },
    {
      name: `concepteur_cao`,
      label: `CAD建模师`,
    },
    {
      name: `joaillier`,
      label: `珠宝师`,
    },
    {
      name: `lapidaire`,
      label: `宝石切割师`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `熔炼师`,
    },
    {
      name: `polisseur`,
      label: `抛光师`,
    },
    {
      name: `diamantaire`,
      label: `钻石切割师`,
    },
    {
      name: `joaillier`,
      label: `珠宝师`,
    },
  ],
}