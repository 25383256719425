export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      icon: `<svg width="97" viewBox="0 0 97 86" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M53.119 20.7857C53.119 23.3367 51.051 25.4048 48.5 25.4048C45.949 25.4048 43.881 23.3367 43.881 20.7857C43.881 18.2347 45.949 16.1667 48.5 16.1667C51.051 16.1667 53.119 18.2347 53.119 20.7857Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M27.7143 19.1996C27.7143 7.87838 37.4056 0 48.2774 0C59.074 0 69.2857 7.81193 69.2857 19.1996C69.2857 22.0087 68.4338 25.0166 67.1805 27.9505C66.0602 30.573 64.5705 33.2483 62.9192 35.8299L64.6233 36.3655L92.6881 26.4349C94.7922 25.6903 97 27.2511 97 29.483V70.5599C97 72.7126 95.6434 74.6317 93.6139 75.3498L64.6631 85.594L32.3465 74.0523L4.35327 84.3883C2.24201 85.1678 0 83.6056 0 81.3551V41.2211C0 39.0683 1.35662 37.1492 3.38606 36.4311L29.4275 27.2165C28.3684 24.4594 27.7143 21.7317 27.7143 19.1996ZM48.2774 4.61905C39.4786 4.61905 32.3333 10.8826 32.3333 19.1996C32.3333 23.2439 34.7147 28.6485 38.2413 34.1676C41.3583 39.0459 45.1555 43.6877 48.2787 47.0261C51.2989 43.8093 55.1837 39.1713 58.4264 34.2661C60.2337 31.5321 61.8124 28.7588 62.9327 26.136C64.063 23.49 64.6667 21.1354 64.6667 19.1996C64.6667 10.9491 57.1514 4.61905 48.2774 4.61905ZM34.6429 37.1111V69.9677L62.3571 79.8656V40.495L60.2002 39.8171C56.886 44.4038 53.2822 48.5303 50.5781 51.3048C49.3124 52.6034 47.2336 52.5953 45.9749 51.3027C42.6004 47.8369 38.2431 42.6593 34.6429 37.1111ZM30.0238 31.9051V69.9861L4.61905 79.3663V41.2211C4.61905 41.0253 4.74238 40.8509 4.92687 40.7856L30.0238 31.9051ZM66.9762 40.4326V79.8758L92.0731 70.9953C92.2576 70.9301 92.381 70.7556 92.381 70.5599V31.4432L66.9762 40.4326Z" fill="white"/></svg>`,
      action: `open-map`,
    },
    {
      name: `toggle_fullscreen`,
      icon: `<svg width="97" viewBox="0 0 97 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.9733 5.18717H5.70588V27.492C5.70588 28.9244 4.5447 30.0856 3.1123 30.0856C1.6799 30.0856 0.518717 28.9244 0.518717 27.492V4.66845C0.518717 2.09013 2.60885 0 5.18717 0H26.9733C28.4057 0 29.5668 1.16119 29.5668 2.59358C29.5668 4.02598 28.4057 5.18717 26.9733 5.18717Z" fill="white"/><path d="M91.8128 26.9733V5.70588H69.508C68.0756 5.70588 66.9144 4.5447 66.9144 3.1123C66.9144 1.6799 68.0756 0.518717 69.508 0.518717H92.3316C94.9099 0.518717 97 2.60885 97 5.18717V26.9733C97 28.4057 95.8388 29.5668 94.4064 29.5668C92.974 29.5668 91.8128 28.4057 91.8128 26.9733Z" fill="white"/><path d="M5.18717 66.9144V45.6471C5.18717 44.2147 4.02598 43.0535 2.59358 43.0535C1.16119 43.0535 0 44.2147 0 45.6471V67.4332C0 70.0115 2.09013 72.1016 4.66845 72.1016H27.492C28.9244 72.1016 30.0856 70.9404 30.0856 69.508C30.0856 68.0756 28.9244 66.9144 27.492 66.9144H5.18717Z" fill="white"/><path d="M70.0267 67.4332H91.2941V45.1283C91.2941 43.6959 92.4553 42.5348 93.8877 42.5348C95.3201 42.5348 96.4813 43.6959 96.4813 45.1283V67.9519C96.4813 70.5302 94.3911 72.6203 91.8128 72.6203H70.0267C68.5943 72.6203 67.4332 71.4591 67.4332 70.0267C67.4332 68.5943 68.5943 67.4332 70.0267 67.4332Z" fill="white"/></svg>`,
      action: `toggle-fullscreen`,
    },
  ]
}