export default {
  name: `tutorial`,
  title: `Инструкции`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `Для кругового обзора нажмите, затем передвигайте мышь`
    },
    {
      name: `arrows_movement`,
      text: `Перемещайтесь с помощью стрелок на полу`,
    },
    {
      name: `hotspots`,
      text: `Нажимайте на кружки, чтобы узнать больше`,
    },
  ],
  continueCTA: `ПРОПУСТИТЬ`,
}