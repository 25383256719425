export default [
  {
    name: `splash_screen`,
    images: {
      background: require(`@/assets/splashscreen/splashscreen_background.png`),
      typo: require(`@/assets/logos/logo.png`),
      logo: require(`@/assets/logos/logo_vca.png`),
      butterflyBlue: require(`@/assets/common/papillon_bleu.png`),
      butterflyYellow: require(`@/assets/common/papillon_jaune.png`),
    },
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        icon: require(`@/assets/splashscreen/touch.gif`)
      },
      {
        name: `movement`,
        icon: require(`@/assets/splashscreen/3d_spots.gif`)
      },
      {
        name: `hotspots`,
        icon: require(`@/assets/splashscreen/2d_spots.gif`)
      },
    ],
    fourGemsImg: require(`@/assets/common/four_gems.png`),
  }
];
