export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `План мастерских`,
    },
    {
      name: `toggle_fullscreen`,
      text: `Полный экран`,
    },
  ]
}