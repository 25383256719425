export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `アトリエのフロアプラン`,
    },
    {
      name: `toggle_fullscreen`,
      text: `全画面表示`,
    },
  ]
}