export default {
  name: `map`,
  floorNames: [
    `الطابق رقم 1`,
    `الطابق رقم 2`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `المرصِّع`,
    },
    {
      name: `concepteur_cao`,
      label: `صانع النموذج بواسطة الكمبيوتر CAD`,
    },
    {
      name: `joaillier`,
      label: `الصائغ`,
    },
    {
      name: `lapidaire`,
      label: `نحّات الأحجار الكريمة`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `خبير صياغة المعدن`,
    },
    {
      name: `polisseur`,
      label: `خبير الصقل`,
    },
    {
      name: `diamantaire`,
      label: `خبير قطع الماس`,
    },
    {
      name: `joaillier`,
      label: `الصائغ`,
    },
  ],
}