<template>
  <div id="spaces-visit" :class="{ visible }">
    <spaces-scene
      :scene="currentScene"
      :scenes-config="scenes"
      :game-config="game"
      :game-status="foundGems"
      :muted="muted || isSceneMuted"
      :gyro-enabled="gyroEnabled"
      :render="render"
      :gyro-datas="gyroDatas"
      :dialog-open="isDialogOpened || isGameDialogOpened"
      :visited-scenes="visitedScenes"
      @scene-mounted="sceneMounted"
      @change-scene="changeScene"
      @open-dialog="openDialog"
      @close-dialog="closeAllDialogs"
      @gem-found="updateGameStatus"
      ref="spacesScene"
      v-if="currentScene"
    />
    <spaces-video
      :video="currentSpacesVideo"
      :visible="isSpacesVideoVisible"
      :controls="true"
      :muted="muted"
      @close="closeSpacesVideo"
    />
    <vca-menu
      :open="isMenuOpened"
      :menu="menu"
      @toggle-menu="toggleMenu"
      @open-map="openMap"
      @toggle-fullscreen="toggleFullscreen"
    />
    <vca-game
      :game-config="game"
      :game-status="foundGems"
      @open-game-dialog="openGameDialog"
    />
    <vca-map
      :open="isMapOpened"
      :map="map"
      @close="closeMap"
      @close-menu="closeMenu"
      @change-scene="changeSceneFromParent"
    />
    <game-dialog
      :visible="isGameDialogOpened"
      :gem="currentGem"
      :game-config="game"
      :game-status="foundGems"
      @close="closeGameDialog"
      @start-over="startOverExperience"
    />
    <vca-dialog
      :visible="isDialogOpened"
      :name="currentDialogName"
      :dialog="currentDialog"
      @open-video="openSpacesVideo"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import SpacesScene from '@/components/SpacesScene';
import SpacesVideo from '@/components/SpacesVideo';
import VcaMenu from '@/components/VcaMenu';
import VcaDialog from '@/components/VcaDialog';
import VcaMap from '@/components/VcaMap';
import VcaGame from '@/components/VcaGame';
import GameDialog from '@/components/GameDialog';

export default {
  components: {
    SpacesScene,
    SpacesVideo,
    VcaMenu,
    VcaDialog,
    VcaMap,
    VcaGame,
    GameDialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    visit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    currentExperience: {
      type: String,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    isMenuOpened: false,
    currentDialogName: `jewellery_workshop`,
    isDialogOpened: false,
    isGameDialogOpened: false,
    isMapOpened: false,
    previousDialogName: null,
    currentSpacesVideo: null,
    isSpacesVideoVisible: false,
    firstSceneName: `place_vendome_1`,
    isChangingScene: false,
    previousSceneName: null,
    currentSceneName: null,
    isSceneVisible: false,
    isSceneTicking: false,
    areHotspotsVisible: false,
    isSceneMuted: false,
    currentGem: ``,
    foundGems: {
      green: false,
      blue: false,
      red: false,
      white: false,
    },
    visitedScenes: null,
  }),

  computed: {
    scenes() {
      return this.visit?.scenes || [];
    },
    game() {
      return this.visit?.game || {};
    },
    dialogs() {
      return this.visit?.dialogs || [];
    },
    map() {
      return this.visit?.map || {};
    },
    menu() {
      return this.visit?.menu || {};
    },
    tutorials() {
      return this.visit?.tutorials || [];
    },
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }
      return currentScene || null;
    },
    previousScene() {
      let previousScene = null;
      if (this.previousSceneName) {
        previousScene = this.getSceneByName(this.previousSceneName);
      }

      return previousScene || null;
    },
    currentDialog() {
      return this.dialogs.find(dialog => dialog.name === this.currentDialogName) || {};
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
    },
    closeMenu() {
      this.isMenuOpened = false;
    },
    openGameDialog() {
      this.isGameDialogOpened = true;
    },
    updateGameStatus(gem) {
      this.currentGem = gem;
      this.openGameDialog();
      this.$set(this.foundGems, gem, true);
      localStorage.setItem('vcaFoundGems', JSON.stringify(this.foundGems));
    },
    toggleFullscreen() {
      this.$emit(`toggle-fullscreen`);
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    getTutorialByName(tutorialName) {
      return this.tutorials.find(tuto => tuto.name === tutorialName);
    },
    openSpacesVideo(video) {
      this.currentSpacesVideo = video;
      this.isSpacesVideoVisible = true;
    },
    async closeSpacesVideo() {
      this.isSpacesVideoVisible = false;
      await this.wait(500);
      this.currentSpacesVideo = null;
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    pauseSound() {
      this.$emit(`pause-sound`);
    },
    resumeSound() {
      this.$emit(`resume-sound`);
    },
    toggleTutorial() {
      this.isTutorialOpened = !this.isTutorialOpened;
    },
    resumeSoundAfterDialogClose() {
      if (!this.currentScene.hasSound) {
        this.resumeSound();
      }
    },
    async startOverExperience() {
      this.changeSceneFromParent(`place_vendome_1`);
      this.closeGameDialog();
      setTimeout(() => {
        this.foundGems = {
          green: false,
          blue: false,
          red: false,
          white: false,
        };
        localStorage.setItem('vcaFoundGems', JSON.stringify(this.foundGems));
      }, 500)
    },
    changeSceneFromParent(scene) {
      if (scene === this.currentSceneName) {
        this.closeMenu();
        this.closeMap();
      } else {
        this.$refs.spacesScene.changeSceneFromParent(scene);
      }
    },
    async changeScene(sceneName) {
      console.log(sceneName);
      if (this.isChangingScene || this.currentSceneName === sceneName) { return; }

      this.isChangingScene = true;

      await this.wait(100);
      this.currentSceneName = sceneName;
      this.saveVisitedScene(sceneName);
    },
    async sceneMounted() {
      this.isChangingScene = false;
      await this.wait();
      this.isSceneTicking = true;
      this.isSceneVisible = true;
      this.areHotspotsVisible = true;
      this.closeMap();
    },
    openHub() {
      this.changeScene(`hub`);
    },
    openMap() {
      this.closeDialog();
      this.closeGameDialog();
      this.isMapOpened = true;
    },
    closeMap() {
      this.isMapOpened = false;
    },
    goHome() {
      this.closeMap();
      if (this.currentSceneName !== this.firstSceneName) {
        this.changeScene(this.firstSceneName);
      }
    },
    openDialog(dialogName) {
      this.currentDialogName = dialogName;
      this.isDialogOpened = true;
    },
    async closeDialog() {
      this.isDialogOpened = false;
      await this.wait(500);
      this.currentDialogName = ``;
    },
    async closeGameDialog() {
      this.isGameDialogOpened = false;
      await this.wait(500);
      this.currentGem = ``;
    },
    closeAllDialogs() {
      this.closeDialog();
      this.closeGameDialog();
    },
    loadVisitedScenes() {
      this.visitedScenes = JSON.parse(localStorage.getItem(`vcaVisitedScenes`));
      if (!this.visitedScenes) {
        this.visitedScenes = {};
        for (const s of this.scenes) {
          if (s.name === `global`) { continue; }
          this.visitedScenes[s.name] = false;
        }
        localStorage.setItem(`vcaVisitedScenes`, JSON.stringify(this.visitedScenes));
      }
    },
    saveVisitedScene(name) {
      if (
        !Object.keys(this.visitedScenes).includes(name)
        || this.visitedScenes[name] === true
      ) { return; }
      this.visitedScenes[name] = true;
      localStorage.setItem(`vcaVisitedScenes`, JSON.stringify(this.visitedScenes));
    }
  },

  created() {
    this.loadVisitedScenes();
  },

  mounted() {
    if (!localStorage.getItem(`vcaFoundGems`)) {
      localStorage.setItem(`vcaFoundGems`, JSON.stringify(this.foundGems));
    }
    this.foundGems = JSON.parse(localStorage.getItem(`vcaFoundGems`));
    setTimeout(() => {
      this.changeScene(this.firstSceneName);
      if (!this.currentDialog) {
        this.currentDialog = this.dialogs[0];
      }
    });
  },

  watch: {
    currentExperience(newExp) {
      this.changeScene(`${newExp}`);
    },
  },
};
</script>

<style lang="scss" scoped>
#position-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: tomato;
}

.spaces-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lose-message {
  width: fit-content;
  position: absolute;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;

  &.visible {
    opacity: 1;
  }
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
