export default [
  {
    name: `place_vendome_2`,
    label: `Откройте для себя Вандомскую площадь`,
  },
  {
    name: `maquettes_etain`,
    label: `Толкните дверь мастерских`,
  },
  {
    name: `entree_ateliers`,
    label: `Знакомство с золотыми руками`,
  },
  {
    name: `polisseur`,
    label: `Полировщик`,
  },
  {
    name: `joaillier_1`,
    label: `Ювелир`,
  },
  {
    name: `joaillier_2`,
    label: `Ювелир`,
  },
  {
    name: `sertisseur`,
    label: `Закрепщик`,
  },
  {
    name: `fondeur`,
    label: `Литейщик`,
  },
  {
    name: `diamantaire`,
    label: `Гранильщик бриллиантов`,
  },
  {
    name: `lapidaire_1`,
    label: `Гранильщик`,
  },
  {
    name: `lapidaire_2`,
    label: `Гранильщик`,
  },
  {
    name: `concepteur_cao`,
    label: `Разработчик 3D-моделей`,
  },
];