<template>
  <div
    class="onboarding-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="`
      background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${config.images.background});
    `"
  >
    <div class="onboarding-content">
      <img class="onboarding-vca-typo" :src="config.images.typo" alt="vca-typo-onboarding" />
      <img class="onboarding-vca-logo" :src="config.images.logo" alt="vca-logo-onboarding" />
      <div class="tutorials-wrapper">
        <div
          class="tutorial-element"
          :class="{ 'left-border': idx > 0 }"
          v-for="(elem, idx) in config.baseTutorial"
          :key="`onboarding-${elem.name}-tuto`"
        >
          <img class="elem-icon" :src="elem.icon" :alt="`onboarding-${elem.name}-tuto-icon`" />
          <p class="elem-text">{{ elem.text }}</p>
        </div>
      </div>
      <div class="gems-wrapper">
        <img class="gems-image" :src="config.fourGemsImg" alt="four-gems-image" />
        <p class="gems-text">{{ config.gameTutorial }}</p>
      </div>
      <!-- <p class="browser-tip">{{ config.browserTip }}</p> -->
      <a @click.prevent="startExperience" class="onboarding-cta">{{ config.startCTA }}</a>

      <img id="butterfly-blue" class="onboarding-butterfly" :src="config.images.butterflyBlue" alt="vca-butterfly-blue-onboarding" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
  },

  methods: {
    startExperience() {
      this.$emit(`close`);
    },
  }
};
</script>

<style lang="scss" scoped>
.onboarding-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .onboarding-content {
    position: relative;
    width: 50%;
    height: 95%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    & > * {
      margin: 1em 0;
    }

    .onboarding-vca-typo {
      width: 40%;
    }

    .onboarding-vca-logo {
      width: calc(40% * 1/3);
    }

    .onboarding-butterfly {
      position: absolute;
      width: 40px;
    }

    #butterfly-blue {
      top: 10%;
      left: 0;
    }

    .tutorials-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .tutorial-element {
        width: calc(30% + 10em);
        padding: 2em 3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .elem-icon {
          width: 40%;
        }

        &.left-border {
          border-left: 1px solid rgba(75, 184, 217, 0.3);
        }
      }
    }

    .gems-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gems-image {
        width: 50%;
      }

      .gems-text {
        margin-bottom: 0;
      }
    }

    .browser-tip {
      width: 50%;
    }

    a {
      padding: 0.75em 2em;
      color: #4bb8d9;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 400;
      border: 1px solid #4bb8d9;
      border-radius: 4em;
      background-color: rgba(75, 184, 217, 0);
      cursor: pointer;

      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background-color: #4bb8d9;
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &.mobile {

    .onboarding-content {
      width: 90%;

      .onboarding-vca-typo {
        width: 70%;
      }

      .onboarding-vca-logo {
        width: calc(70% * 1/3);
      }

      #butterfly-blue {
        top: 5%;
      }

      .tutorials-wrapper {
        flex-direction: column;
        justify-content: center;

        .tutorial-element {
          width: 70%;
          padding: 1em 0;

          p {
            margin-bottom: 0;
          }

          .elem-icon {
            width: 20%;
          }

          &.left-border {
            border: none;
          }
        }
      }

      .gems-wrapper,
      .browser-tip {
        display: none;
      }
    }
    @media all and (max-height: 667px) {
      .onboarding-content {
        .onboarding-vca-typo {
          width: 30vh;
        }

        .onboarding-vca-logo {
          width: calc(30vh * 1/3);
        }

        .tutorials-wrapper {
          margin: 0;
          .tutorial-element {
            width: 80%;
            padding: 2vh;
            .elem-icon{
              width: 7vh;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>