export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `工坊地图 `,
    },
    {
      name: `toggle_fullscreen`,
      text: `全屏模式`,
    },
  ]
}