<template>
  <div
    class="vca-dialog"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
  >
    <div class="image-wrapper visible">
      <img :src="dialog.img" :alt="`${dialog.name}-image`" v-if="hasDialog" />
    </div>
    <template v-if="hasDialog">
      <div class="content-wrapper">
        <div class="dialog-title">{{ dialog.title }}</div>
        <div class="dialog-content">
          <p
            class="dialog-paragraph"
            v-for="(paragraph, idx) in dialog.content"
            :key="`${dialog.name}-paragraph-${idx + 1}`"
          >
            {{ paragraph }}
          </p>
        </div>
        <button
          class="dialog-video-btn"
          @click.stop="openVideo"
          v-if="dialog.video && dialog.video.length"
        >
          {{ dialog.videoCTA }}
        </button>
      </div>
      <button class="close-button" @click.stop="close">×</button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dialog: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    }
  },

  computed: {
    hasDialog() {
      const keys = Object.keys(this.dialog);

      if (!keys.includes('name') ||
        !keys.includes('title') ||
        !keys.includes('content') ||
        !keys.includes('img')) {
        return false;
      }
      return true;
    }
  },

  methods: {
    close() {
      this.$emit(`close`);
    },
    openVideo() {
      if (this.dialog.video && this.dialog.video.length) {
        this.$emit(`open-video`, { src: this.dialog.video, hasSound: true });
      }
    },
  },
};
</script>

<style lang="scss">

.vca-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .image-wrapper {
    width: 100%;
    height: 40%;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.visible {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .content-wrapper {
    width: 100%;
    padding: 7% 1.5em 5%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    overflow-y: auto;

    .dialog-title {
      color: #4bb8d9;
      font-size: 1.2em;
      font-weight: 800;
      margin-bottom: 1.2em;
    }

    .dialog-paragraph {
      font-size: 15px;
      line-height: 25px;
      padding: 0 1em;
    }

    .dialog-content {
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: #d3d3d3 transparent;
      width: 100%;
      height: 100%;
      padding: 0 2em;
      margin-bottom: 2em;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d3d3d3;
        border-radius: 20px;
        border: 3px solid #d3d3d3;
      }

      &::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        // margin-top: 20px;
      }

      &::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        // margin-bottom: 20px;
      }
    }
    .dialog-video-btn {
      font-size: 12px;
      margin-top: auto;
      padding: 1em 2em;
      color: #4bb8d9;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      border: 1px solid #4bb8d9;
      border-radius: 4em;
      background-color: #ffffff;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        color: #ffffff;
        background-color: #4bb8d9;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &:not(.mobile) {
    width: 900px;
    max-width: 80%;
    height: 60%;
    flex-direction: row;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > div {
      width: 50%;
    }

    .image-wrapper {
      height: 100%;
    }

    .content-wrapper {
      height: 100%;
      font-size: 16px;
    }
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #4bb8d9;
    border: none;
    background: none;
    font-size: 30px;
    line-height: 17px;

    transition: color 0.5s ease-in-out;

    &:hover {
      color: darken(#4bb8d9, 20);
    }
  }
}
</style>