export default [
  {
    name: `splash_screen`,
    startCTA: `START`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `A 360° tour of our High Jewelry workshops`,
      },
      {
        name: `movement`,
        text: `Move around using the arrows that appear on the floor`,
      },
      {
        name: `hotspots`,
        text: `Click on each circle to discover more`,
      },
    ],
    gameTutorial: `Collect gemstones throughout your tour`,
    // browserTip: `For an optimal experience, we recommend using the Google Chrome browser for this tour.`,
    startCTA: `CONTINUE`,
  }
];
