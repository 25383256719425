export default {
  name: `map`,
  floorNames: [
    `FLOOR N1`,
    `FLOOR N2`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `The Gem-Setter`,
    },
    {
      name: `concepteur_cao`,
      label: `The CAD designer`,
    },
    {
      name: `joaillier`,
      label: `The Jeweler`,
    },
    {
      name: `lapidaire`,
      label: `The Lapidary`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `The Caster`,
    },
    {
      name: `polisseur`,
      label: `The Polisher`,
    },
    {
      name: `diamantaire`,
      label: `The Diamond-Cutter`,
    },
    {
      name: `joaillier`,
      label: `The Jeweller`,
    },
  ],
}