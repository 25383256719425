export default {
  name: `tutorial`,
  title: `教程`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `点击并滑动鼠标，360度全景照片一览无余`
    },
    {
      name: `arrows_movement`,
      text: `可通过地面箭头进行移动`,
    },
    {
      name: `hotspots`,
      text: `点击圆点即可了解更多内容`,
    },
  ],
  continueCTA: `跳过`,
}