<template>
  <div
    class="vca-game"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <div
      class="game-gems-container"
      :class="{
        pointer: gameFinished
      }"
      @click.prevent="toggleFinishedGameDialog"
    >
      <div class="game-gem-wrapper">
        <img class="gem-empty" :src="gameConfig.gemsEmpty.green" alt="game-status-green-gem-empty" />
        <img class="gem-full" :class="{ found: gameStatus.green }" :src="gameConfig.gemsFull.green" alt="game-status-green-gem-full" />
      </div>
      <div class="game-gem-wrapper">
        <img class="gem-empty" :src="gameConfig.gemsEmpty.blue" alt="game-status-blue-gem-empty" />
        <img class="gem-full" :class="{ found: gameStatus.blue }" :src="gameConfig.gemsFull.blue" alt="game-status-blue-gem-full" />
      </div>
      <div class="game-gem-wrapper">
        <img class="gem-empty" :src="gameConfig.gemsEmpty.red" alt="game-status-red-gem-empty" />
        <img class="gem-full" :class="{ found: gameStatus.red }" :src="gameConfig.gemsFull.red" alt="game-status-red-gem-full" />
      </div>
      <div class="game-gem-wrapper">
        <img class="gem-empty" :src="gameConfig.gemsEmpty.white" alt="game-status-white-gem-empty" />
        <img class="gem-full" :class="{ found: gameStatus.white }" :src="gameConfig.gemsFull.white" alt="game-status-white-gem-full" />
      </div>
    </div>
    <div class="game-gems-hover-text">{{ gameConfig.gemsHover }}</div>
  </div>
</template>

<script>
export default {
  props: {
    gameConfig: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Object,
      required: true,
    },
  },

  computed: {
    gameFinished() {
      return this.gameStatus.green && this.gameStatus.blue && this.gameStatus.red && this.gameStatus.white;
    },
  },

  methods: {
    toggleFinishedGameDialog() {
      if (this.gameFinished) {
        this.$emit(`open-game-dialog`);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.vca-game {
  position: absolute;
  top: 5%;
  left: 2%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &, & * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }


  .game-gems-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &.pointer {
      cursor: pointer;
    }

    .game-gem-wrapper {
      height: 30px;
      width: 20%;
      position: relative;

      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      .gem-full {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.found {
          opacity: 1;
        }
      }
    }
  }

  .game-gems-hover-text {
    font-size: 12px;
    text-align: left;
    color: #ffffff;
    font-weight: 800;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    .game-gem-wrapper .gem-full,
    .game-gems-hover-text {
      opacity: 1;
    }
  }

  &:not(.mobile) {
    width: 10%;
  }
}
</style>