export default {
  name: `tutorial`,
  title: `Tutorial`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `Click and slide your finger to look around the 360° photo`
    },
    {
      name: `arrows_movement`,
      text: `Move around using the arrows that appear on the floor`,
    },
    {
      name: `hotspots`,
      text: `Click on each circle to discover more`,
    },
  ],
  continueCTA: `SKIP`,
}
