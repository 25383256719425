export default {
  name: `map`,
  floorNames: [
    `1층`,
    `2층`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `스톤 세터`,
    },
    {
      name: `concepteur_cao`,
      label: `CAD 디자이너`,
    },
    {
      name: `joaillier`,
      label: `주얼러`,
    },
    {
      name: `lapidaire`,
      label: `보석 세공사`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `제련사`,
    },
    {
      name: `polisseur`,
      label: `폴리셔`,
    },
    {
      name: `diamantaire`,
      label: `다이아몬드 세공사`,
    },
    {
      name: `joaillier`,
      label: `주얼러`,
    },
  ],
}