export default [
  {
    name: `place_vendome_2`,
    label: `اكتشفوا ساحة فاندوم`,
  },
  {
    name: `maquettes_etain`,
    label: `ادفعوا باب ورش العمل`,
  },
  {
    name: `entree_ateliers`,
    label: `تعرّفوا إلى الأيادي الذهبية`,
  },
  {
    name: `polisseur`,
    label: `خبير الصقل`,
  },
  {
    name: `joaillier_1`,
    label: `الصائغ`,
  },
  {
    name: `joaillier_2`,
    label: `الصائغ`,
  },
  {
    name: `sertisseur`,
    label: `المرصِّع`,
  },
  {
    name: `fondeur`,
    label: `خبير صياغة المعدن`,
  },
  {
    name: `diamantaire`,
    label: `خبير قطع الماس`,
  },
  {
    name: `lapidaire_1`,
    label: `نحّات الأحجار الكريمة`,
  },
  {
    name: `lapidaire_2`,
    label: `نحّات الأحجار الكريمة`,
  },
  {
    name: `concepteur_cao`,
    label: `صانع النموذج بواسطة الكمبيوتر CAD`,
  },
];