export default [
  {
    name: `place_vendome_2`,
    label: `ヴァンドーム広場を見る`,
  },
  {
    name: `maquettes_etain`,
    label: `ワークショップの扉を押す`,
  },
  {
    name: `entree_ateliers`,
    label: `マンドールに会う`,
  },
  {
    name: `polisseur`,
    label: `研磨師`,
  },
  {
    name: `joaillier_1`,
    label: `宝飾職人`,
  },
  {
    name: `joaillier_2`,
    label: `宝飾職人`,
  },
  {
    name: `sertisseur`,
    label: `セッティング職人`,
  },
  {
    name: `fondeur`,
    label: `鋳物職人`,
  },
  {
    name: `diamantaire`,
    label: `ダイヤモンドカット職人`,
  },
  {
    name: `lapidaire_1`,
    label: `宝石カット職人`,
  },
  {
    name: `lapidaire_2`,
    label: `宝石カット職人`,
  },
  {
    name: `concepteur_cao`,
    label: `CADデザイナー`,
  },
];