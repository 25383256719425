export default {
  name: `map`,
  floorNames: [
    `ÉTAGE N1`,
    `ÉTAGE N2`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `Sertisseur`,
    },
    {
      name: `concepteur_cao`,
      label: `Concepteur CAO`,
    },
    {
      name: `joaillier`,
      label: `Joailler`,
    },
    {
      name: `lapidaire`,
      label: `Lapidaire`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `Fondeur`,
    },
    {
      name: `polisseur`,
      label: `Polisseur`,
    },
    {
      name: `diamantaire`,
      label: `Diamantaire`,
    },
    {
      name: `joaillier`,
      label: `Joaillier`,
    },
  ],
}