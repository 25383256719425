export default {
  name: `map`,
  floorNames: [
    `Этаж №1`,
    `Этаж №2`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `Закрепщик`,
    },
    {
      name: `concepteur_cao`,
      label: `Разработчик 3D-моделей`,
    },
    {
      name: `joaillier`,
      label: `Ювелир`,
    },
    {
      name: `lapidaire`,
      label: `Гранильщик`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `Литейщик`,
    },
    {
      name: `polisseur`,
      label: `Полировщик`,
    },
    {
      name: `diamantaire`,
      label: `Гранильщик бриллиантов`,
    },
    {
      name: `joaillier`,
      label: `Ювелир`,
    },
  ],
}