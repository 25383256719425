export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    img: require(`@/assets/dialogs/images/atelier_vca.png`),
    title: `高级珠宝 工坊`,
    content: [
      `一个多世纪以来，世家将对宝石的热爱、独家精湛工艺与高超技术共冶一炉。`,
      `世家工坊静立于巴黎芳登广场之上，源源不断地打造出众多高级珠宝作品，缔造悠久传承`,
      `工坊内，宝石切割师、镶嵌师和珠宝师对珍贵宝石与金属进行悉心打造。`,
      `通过本次参观，您将领略到每件世家珠宝杰作蕴含的精致细节与卓越品质，唯有遵循严苛要求方能造就。`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`, // path de l'image, elles se trouvent dans le dossier src/assets/dialogs/
    title: `国际市场营销团队`, // titre de la dialog
    content: [
      `国际市场营销团队与各个部门（如宝石、设计及其他工作室）携手合作，共同创造系列作品。`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `高级珠宝工坊`,
    content: [
      `世家作品都是从设计图纸开始创作。`,
      `每件高级珠宝作品都凝聚着团队的心血，荟萃不同技艺，让我们来一探究竟。`,
    ],
  },
  {
    name: `creation_studio`,
    title: `设计工作室`,
    content: [
      `创作的第一步就是绘制设计图。工作室的设计师们构思全新作品，再以线条和色彩呈现出来。`,
      `如果技术上确实可行，且得到梵克雅宝总裁兼首席执行官尼古拉斯·博斯先生的批准，即可绘制水粉画，展现更写实的立体造型、色彩与光影效果。`,
      `有时还需要制作模型，用以验证作品的技术可行性。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/dessinatrice/dessinatrice.zh_CN.mp4`),
  },
  {
    name: `stones_expert`,
    title: `宝石专家`,
    content: [
      `宝石专家精于鉴别及甄选珍贵宝石和装饰宝石。`,
      `他们致力于挑选符合世家高品质标准的精美宝石。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.zh_CN.mp4`),
  },
  {
    name: `tin_models`,
    title: `锡蜡模型`,
    content: [
      `模型制作师负责打造作品模型，便于后续观察立体造型。`,
      `模型在水粉画设计图的基础上打造，通常使用锡蜡和水钻，将水粉画转变成三维立体模型。`,
      `这一工序将作品的立体造型展露无遗，有助于规划技术方案，并初步估计宝石重量。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.zh_CN.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `抛光师的工作内容`,
    content: [
      `抛光师负责通过抛光工序让珠宝作品绽放光彩。`,
      `抛光旨在让整件作品的内外表面都熠熠生辉。`,
      `镶嵌前后及最终组装完成后，抛光师都会参与进来，用手里的细丝线、刷子和抛光膏完成抛光工序。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.zh_CN.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `抛光师的工具`,
    content: [
      `正式镶嵌宝石之前，金属框架的每一面都要经过仔细抛光。`,
      `抛光或抛磨内表面时会用到细丝线和抛光膏。`,
      `每件作品所需的抛光工具各不相同，无论尺寸还是形状都要进行适配调整。`,
    ],
  },
  {
    name: `quality_controller`,
    title: `质量检验员 `,
    content: [
      `质量检验员的职责是在珠宝作品于精品店进行销售之前确保其合格性和品质达标。 `,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `工作台`,
    content: [
      `锉台：每个珠宝师都有自己的专属锉台。锉台的使用方法因工作方式而异。珠宝师们在作业时可能会更换工作台，但会随身携带专属锉台。`,
      `皮革垫：皮革垫一般放置在工作台下方，用于回收各种小部件和小宝石，以及工作中产生的金属粉末。`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `珠宝师的工作内容`,
    content: [
      `珠宝师总是与珍贵金属打交道，他们负责根据水粉画和3D模型打造出作品的三维立体造型结构。`,
      `世家珠宝师工艺精湛，专注打磨作品细节，提升佩戴舒适度，同时兼顾构造的独创性，确保实现精细的“Mise à jour”工艺，让光线得以穿透宝石，璀璨闪耀。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.zh_CN.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `镶嵌师的工作内容`,
    content: [
      `镶嵌师负责将珍贵宝石或装饰宝石嵌入贵金属中，令其在珠宝作品中尽显华美。`,
      `为此，镶嵌师必须将宝石牢牢镶嵌到位，同时尽可能隐藏金属。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.zh_CN.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `镶嵌棒`,
    content: [
      `镶嵌师会用镶嵌棒暂时固定住作品的金属框架，提高镶嵌工序的稳定性。`,
    ],
  },
  {
    name: `tools_variety`,
    title: `各类工具`,
    content: [
      `镶嵌师经手的宝石形状各异，尺寸也不尽相同。正因如此，镶嵌师的工具格外丰富：錾刀、吸珠针、镶嵌棒等……`,
      `镶嵌师有时也会根据手中的项目自创一些趁手的工具。`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `熔炼师的工作内容`,
    content: [
      `珠宝师在绿色的蜡模上完成雕塑细节之后，便会交给熔炼师，赋予作品最终的金属形态。`,
      `熔炼师会使用一种被称为“脱蜡法”的技法，先用石膏制作出模型，随即送入窑炉，将蜡模融化，最后在石膏模型中浇注金属液体。 `,
      `为了确保作品质量，熔炼师在这一步中必须展现出纯熟技艺，对细节精益求精。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.zh_CN.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `钻石切割师的工作内容`,
    content: [
      `钻石切割师是切割宝石的专家，尤其擅长切割钻石，能令原石充分绽放耀眼光芒。`,
      `钻石极为坚硬，切割时需要结合精湛工艺与专业技巧，才能释放闪耀的光泽与火彩。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.zh_CN.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `隐密式镶嵌`,
    content: [
      `1933年，世家正式为隐密式镶嵌注册专利。要掌握这项技术，经验、细致与耐心缺一不可。`,
      `宝石切割师需要逐一切割宝石，再将其镶嵌到极细的轨道上，最终完全隐匿金属结构。`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `宝石切割师的工作内容`,
    content: [
      `宝石切割师匠负责打磨珍贵宝石和装饰宝石。`,
      `宝石切割师的精湛技艺在隐密式镶嵌中展现得淋漓尽致。`,
      `掌握这一精湛技艺的宝石切割师可谓屈指可数。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.zh_CN.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `CAD建模师 的工作内容`,
    content: [
      `创作较为复杂的作品时，CAD（Computer Aided Design，计算机辅助设计）建模师也会参与其中，与珠宝师紧密合作，将水粉画上的作品转化为3D数字形象。`,
      `通过CAD建模师的努力，传统工艺与现代技术在此碰撞出新的火花。`,
      `这一工序有助于深入观察作品的立体造型，构想其中的各类精妙构造，在交由工坊制作前分析出可能的技术难点。`,
    ],
    videoCTA: `查看业务视频`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.zh_CN.mp4`),
  },
];
