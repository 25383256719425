export default {
  name: `map`,
  vcaLogoImg: require(`@/assets/logos/logo.png`),
  firstFloorImg: require(`@/assets/map/floor_1.png`),
  secondFloorImg: require(`@/assets/map/floor_2.png`),
  firstFloorSpots: [
    {
      name: `sertisseur`,
      scene: `sertisseur`,
      hoverImg: require(`@/assets/map/sertisseur.png`),
      coords: {
        x: 6,
        y: 50,
      },
    },
    {
      name: `concepteur_cao`,
      scene: `concepteur_cao`,
      hoverImg: require(`@/assets/map/concepteur_cao.png`),
      coords: {
        x: 6,
        y: 88,
      },
    },
    {
      name: `joaillier`,
      scene: `joaillier_2`,
      hoverImg: require(`@/assets/map/joaillier_2.png`),
      coords: {
        x: 54,
        y: 86,
      },
    },
    {
      name: `lapidaire`,
      scene: `lapidaire_2`,
      hoverImg: require(`@/assets/map/lapidaire.png`),
      coords: {
        x: 78,
        y: 54,
      },
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      scene: `fondeur`,
      hoverImg: require(`@/assets/map/fondeur.png`),
      coords: {
        x: 6,
        y: 50,
      },
    },
    {
      name: `polisseur`,
      scene: `polisseur`,
      hoverImg: require(`@/assets/map/polisseur.png`),
      coords: {
        x: 30,
        y: 40,
      },
    },
    {
      name: `diamantaire`,
      scene: `diamantaire`,
      hoverImg: require(`@/assets/map/diamantaire.png`),
      coords: {
        x: 4,
        y: 90,
      },
    },
    {
      name: `joaillier`,
      scene: `joaillier_1`,
      hoverImg: require(`@/assets/map/joaillier_1.png`),
      coords: {
        x: 32,
        y: 83,
      },
    },
  ],
}