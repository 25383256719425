export default [
  {
    name: `global`,
    sphere: require(`@/assets/glbs/sphere.glb`),
    arrow0: require(`@/assets/texs/arrow_00.png`),
    arrow1: require(`@/assets/texs/arrow_01.png`),
    arrow2: require(`@/assets/texs/arrow_02.png`),
    arrow3: require(`@/assets/texs/arrow_03.png`),
    arrowHighlight: require(`@/assets/texs/arrow_0H.png`),
  },
  {
    name: `place_vendome_1`,
    tex: require(`@/assets/360/place_vendome_1.jpg`),
    initialCamera: {
      lon: 158,
      azi: 5,
    },
    hotspots: {
      spots2D: [],
      spots3D: [
        {
          name: 'scene_change_vendome_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `place_vendome_2`,
            },
          },
          image: 'arrow_00',
          size: 2.72,
          longitude: -178.5,
          azimuth: -18.5,
          inclinationX: 63,
          inclinationY: 0,
          direction: -1,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `place_vendome_2`,
    tex: require(`@/assets/360/place_vendome_2.jpg`),
    initialCamera: {
      lon: 179.5,
      azi: 3,
    },
    hotspots: {
      spots2D: [
        {
          name: `jewellery_workshop`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `jewellery_workshop`,
            },
          },
          longitude: 179.5,
          azimuth: 3,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_maquettes_etain',
          action: {
            type: `change-scene`,
            args: {
              scene: `maquettes_etain`,
            },
          },
          image: 'arrow_00',
          size: 2.3,
          longitude: 179.5,
          azimuth: -13,
          inclinationX: 60,
          inclinationY: 0,
          direction: 0,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `maquettes_etain`,
    tex: require(`@/assets/360/maquettes_etain.jpg`),
    initialCamera: {
      lon: -179.5,
      azi: -7,
    },
    hotspots: {
      spots2D: [
        {
          name: `marketing_teams`,
          type: `basic-spot`,
          category: `number`,
          action: {
            type: `dialog`,
            args: {
              dialog: `marketing_teams`,
            },
          },
          longitude: 141,
          azimuth: 2,
        },
        {
          name: `high_jewellery`,
          type: `basic-spot`,
          category: `number`,
          action: {
            type: `dialog`,
            args: {
              dialog: `high_jewellery`,
            },
          },
          longitude: 163,
          azimuth: -21.5,
        },
        {
          name: `creation_studio`,
          type: `basic-spot`,
          category: `number`,
          action: {
            type: `dialog`,
            args: {
              dialog: `creation_studio`,
            },
          },
          longitude: 171,
          azimuth: 16,
        },
        {
          name: `stones_expert`,
          type: `basic-spot`,
          category: `number`,
          action: {
            type: `dialog`,
            args: {
              dialog: `stones_expert`,
            },
          },
          longitude: -146,
          azimuth: -19,
        },
        {
          name: `tin_models`,
          type: `basic-spot`,
          category: `number`,
          action: {
            type: `dialog`,
            args: {
              dialog: `tin_models`,
            },
          },
          longitude: -170.5,
          azimuth: 4.5,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_entree_ateliers',
          action: {
            type: `change-scene`,
            args: {
              scene: `entree_ateliers`,
            },
          },
          image: 'arrow_00',
          size: 2,
          longitude: 131,
          azimuth: -32,
          inclinationX: -20,
          inclinationY: 53,
          direction: -81,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `entree_ateliers`,
    tex: require(`@/assets/360/entree_ateliers.jpg`),
    initialCamera: {
      lon: -140.8,
      azi: -9.8,
    },
    hotspots: {
      spots2D: [
        {
          name: `green_gem`,
          type: `game-spot`,
          action: {
            type: `found-gem`,
            args: {
              color: `green`,
            },
          },
          longitude: -152.5,
          azimuth: -9,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_maquettes_etain',
          action: {
            type: `change-scene`,
            args: {
              scene: `maquettes_etain`,
            },
          },
          image: 'arrow_00',
          size: 1.85,
          longitude: 48,
          azimuth: -24,
          inclinationX: 60,
          inclinationY: -5,
          direction: -15,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_polisseur',
          action: {
            type: `change-scene`,
            args: {
              scene: `polisseur`,
            },
          },
          image: 'arrow_00',
          size: 1.6,
          longitude: -33.5,
          azimuth: -11.5,
          inclinationX: 62,
          inclinationY: 0,
          direction: 8,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_lapidaire_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `lapidaire_1`,
            },
          },
          image: 'arrow_00',
          size: 2.3,
          longitude: -100.5,
          azimuth: -34.5,
          inclinationX: -34,
          inclinationY: -36,
          direction: 87,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_joaillier_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `joaillier_1`,
            },
          },
          image: 'arrow_00',
          size: 1.8,
          longitude: -148,
          azimuth: -20,
          inclinationX: 53,
          inclinationY: 0,
          direction: -66,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `polisseur`,
    tex: require(`@/assets/360/polisseur.jpg`),
    initialCamera: {
      lon: 169.6,
      azi: -25.6,
    },
    hotspots: {
      spots2D: [
        {
          name: `blue_gem`,
          type: `game-spot`,
          action: {
            type: `found-gem`,
            args: {
              color: `blue`,
            },
          },
          longitude: 130,
          azimuth: -16.9,
        },
        {
          name: `profession_polisher`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_polisher`,
            },
          },
          longitude: 169,
          azimuth: -24,
        },
        {
          name: `polishing_tools`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `polishing_tools`,
            },
          },
          longitude: -137,
          azimuth: -35,
        },
        {
          name: `quality_controller`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `quality_controller`,
            },
          },
          longitude: -102.3,
          azimuth: -5.3,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_entree_ateliers',
          action: {
            type: `change-scene`,
            args: {
              scene: `entree_ateliers`,
            },
          },
          image: 'arrow_00',
          size: 2.1,
          longitude: -85,
          azimuth: -41,
          inclinationX: 38,
          inclinationY: 0,
          direction: -45,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `joaillier_1`,
    tex: require(`@/assets/360/joaillier_1.jpg`),
    initialCamera: {
      lon: 158,
      azi: -19.8,
    },
    hotspots: {
      spots2D: [
        {
          name: `workbench`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `workbench`,
            },
          },
          longitude: 164.5,
          azimuth: -28.5,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_entree_ateliers',
          action: {
            type: `change-scene`,
            args: {
              scene: `entree_ateliers`,
            },
          },
          image: 'arrow_00',
          size: 2,
          longitude: -73,
          azimuth: -38,
          inclinationX: 43,
          inclinationY: 0,
          direction: 71,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_fondeur',
          action: {
            type: `change-scene`,
            args: {
              scene: `fondeur`,
            },
          },
          image: 'arrow_00',
          size: 1.75,
          longitude: -101,
          azimuth: -27,
          inclinationX: 53,
          inclinationY: 0,
          direction: 7,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_diamantaire',
          action: {
            type: `change-scene`,
            args: {
              scene: `diamantaire`,
            },
          },
          image: 'arrow_00',
          size: 2,
          longitude: -130,
          azimuth: -36,
          inclinationX: 43,
          inclinationY: 0,
          direction: -60,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `joaillier_2`,
    tex: require(`@/assets/360/joaillier_2.jpg`),
    initialCamera: {
      lon: 150.5,
      azi: -17.8,
    },
    hotspots: {
      spots2D: [
        {
          name: `profession_jeweller`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_jeweller`,
            },
          },
          longitude: 150.5,
          azimuth: -17.8,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_sertisseur',
          action: {
            type: `change-scene`,
            args: {
              scene: `sertisseur`,
            },
          },
          image: 'arrow_00',
          size: 2.2,
          longitude: 100,
          azimuth: -27,
          inclinationX: 57,
          inclinationY: 0,
          direction: 54,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `sertisseur`,
    tex: require(`@/assets/360/sertisseur.jpg`),
    initialCamera: {
      lon: 104.5,
      azi: -29,
    },
    hotspots: {
      spots2D: [
        {
          name: `profession_crimper`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_crimper`,
            },
          },
          longitude: 95.7,
          azimuth: -6.7,
        },
        {
          name: `crimping_stick`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `crimping_stick`,
            },
          },
          longitude: 108.9,
          azimuth: -25.5,
        },
        {
          name: `tools_variety`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `tools_variety`,
            },
          },
          longitude: 129.5,
          azimuth: -29.5,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_concepteur_cao',
          action: {
            type: `change-scene`,
            args: {
              scene: `concepteur_cao`,
            },
          },
          image: 'arrow_00',
          size: 1.6,
          longitude: 168.5,
          azimuth: -28.5,
          inclinationX: 53,
          inclinationY: 0,
          direction: -16,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `fondeur`,
    tex: require(`@/assets/360/fondeur.jpg`),
    initialCamera: {
      lon: 131,
      azi: -19,
    },
    hotspots: {
      spots2D: [
        {
          name: `profession_founder`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_founder`,
            },
          },
          longitude: 84.5,
          azimuth: -11,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_entree_ateliers',
          action: {
            type: `change-scene`,
            args: {
              scene: `entree_ateliers`,
            },
          },
          image: 'arrow_00',
          size: 1.9,
          longitude: 84.5,
          azimuth: -39.5,
          inclinationX: 45,
          inclinationY: 0,
          direction: -58,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `diamantaire`,
    tex: require(`@/assets/360/diamantaire.jpg`),
    initialCamera: {
      lon: -179,
      azi: -33,
    },
    hotspots: {
      spots2D: [
        {
          name: `profession_diamond_cutter`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_diamond_cutter`,
            },
          },
          longitude: -155.5,
          azimuth: -24.2,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_joaillier_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `joaillier_1`,
            },
          },
          image: 'arrow_00',
          size: 2,
          longitude: 30,
          azimuth: -46,
          inclinationX: 38,
          inclinationY: 0,
          direction: 17,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `lapidaire_1`,
    tex: require(`@/assets/360/lapidaire_1.jpg`),
    initialCamera: {
      lon: 130.7,
      azi: -17.5,
    },
    hotspots: {
      spots2D: [
        {
          name: `red_gem`,
          type: `game-spot`,
          action: {
            type: `found-gem`,
            args: {
              color: `red`,
            },
          },
          longitude: 132.7,
          azimuth: -26.5,
        },
        {
          name: `mysterious_crimp`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `mysterious_crimp`,
            },
          },
          longitude: -164.7,
          azimuth: -19.8,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_lapidaire_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `lapidaire_2`,
            },
          },
          image: 'arrow_00',
          size: 1.9,
          longitude: 107,
          azimuth: -38,
          inclinationX: 43,
          inclinationY: 0,
          direction: 31,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_joaillier_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `joaillier_2`,
            },
          },
          image: 'arrow_00',
          size: 2.2,
          longitude: 27,
          azimuth: -50,
          inclinationX: 29,
          inclinationY: -2,
          direction: -22,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `lapidaire_2`,
    tex: require(`@/assets/360/lapidaire_2.jpg`),
    initialCamera: {
      lon: -178.2,
      azi: -16,
    },
    hotspots: {
      spots2D: [
        {
          name: `profession_lapidary`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_lapidary`,
            },
          },
          longitude: -168,
          azimuth: -19,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_lapidaire_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `lapidaire_1`,
            },
          },
          image: 'arrow_00',
          size: 2.3,
          longitude: -110,
          azimuth: -35,
          inclinationX: 54,
          inclinationY: 0,
          direction: -49,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `concepteur_cao`,
    tex: require(`@/assets/360/concepteur_cao.jpg`),
    initialCamera: {
      lon: -175,
      azi: -10.7,
    },
    hotspots: {
      spots2D: [
        {
          name: `white_gem`,
          type: `game-spot`,
          action: {
            type: `found-gem`,
            args: {
              color: `white`,
            },
          },
          longitude: 125.5,
          azimuth: -5,
        },
        {
          name: `profession_cao_modeler`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `profession_cao_modeler`,
            },
          },
          longitude: -168.2,
          azimuth: -6.7,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_entree_ateliers',
          action: {
            type: `change-scene`,
            args: {
              scene: `entree_ateliers`,
            },
          },
          image: 'arrow_00',
          size: 2,
          longitude: 117,
          azimuth: -38,
          inclinationX: 45,
          inclinationY: 0,
          direction: 57,
          clickShape: 'circle',
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
];