<template>
  <div
    class="vca-game-dialog"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
      finished: gameFinished
    }"
  >
    <div class="gem-found-wrapper" v-if="!gameFinished">
      <div class="gem-image-wrapper">
        <div class="sparkles sparkles-left">
          <div class="small-sparkle" />
          <div class="large-sparkle" />
        </div>
        <img class="gem-image" :src="gemImage" :alt="`${gem}-gem`" />
        <div class="sparkles sparkles-right">
          <div class="small-sparkle" />
          <div class="large-sparkle" />
        </div>
      </div>
      <div class="gem-found-title">{{ gameConfig.gemFound.congrats }}</div>
      <div class="gem-found-content">{{ gameConfig.gemFound.foundMessage }}</div>
      <a class="game-dialog-cta" @click.prevent="close">{{ gameConfig.gemFound.continueCTA }}</a>
    </div>
    <div class="game-finished-wrapper" v-else>
      <div class="game-finished-congrats-wrapper">
        <div class="game-finished-congrats">{{ gameConfig.gameWon.congrats }}</div>
        <img class="game-finished-butterfly" :src="gameConfig.butterfly" alt="blue-butterfly" />
      </div>
      <img class="game-finished-four-gems" :src="gameConfig.fourGemsImg" alt="four-gems" />
      <div class="gem-found-title">{{ gameConfig.gameWon.gemsFound }}</div>
      <div class="gem-found-content">{{ gameConfig.gameWon.explanation }}</div>
      <div class="gem-found-content">
        {{ gameConfig.gameWon.locatedInChina }}
        <br />
        {{ gameConfig.gameWon.locatedInOther }}
      </div>
      <div class="game-dialog-cta-wrapper">
        <a class="game-dialog-cta" @click.prevent="close">{{ gameConfig.gameWon.continueCTA }}</a>
        <a class="game-dialog-cta" @click.prevent="startOver">{{ gameConfig.gameWon.startOverCTA }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gem: {
      type: String,
      required: false,
      default: () => ``,
    },
    gameConfig: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Object,
      required: true,
    },
  },

  computed: {
    gameFinished() {
      return this.gameStatus.green && this.gameStatus.blue && this.gameStatus.red && this.gameStatus.white;
    },
    gemImage() {
      return this.gameConfig.gemsFull[this.gem];
    },
    dialogVisible() {
      return this.visible && (this.gem.length || this.gameFinished);
    },
  },

  methods: {
    close() {
      this.$emit(`close`);
    },
    startOver() {
      this.$emit(`start-over`, `place_vendome_1`);
    },
  }
};
</script>

<style lang="scss" scoped>
.vca-game-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 8% 0;
  // height: 40%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &.finished {
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
  }


  .gem-found-title {
    font-size: 1.2em;
    font-weight: 700;
    margin: 1em 0;
  }

  .gem-found-content {
    font-size: 0.8em;
    margin: 1em 0;
    padding: 0 10%;
  }

  .game-dialog-cta {
    padding: 0.5em 2em;
    margin-top: 2em;
    cursor: pointer;
    border: 1px solid #4bb8d9;
    border-radius: 4em;
    color: #4bb8d9;
    font-size: 0.8em;
    background-color: #ffffff;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      color: #ffffff;
      background-color: #4bb8d9;
    }
  }

  .gem-found-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .gem-image-wrapper {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      .gem-image {
        width: 50%;
      }

      .sparkles {
        width: 25%;
        height: 100%;
        position: relative;

        .small-sparkle,
        .large-sparkle {
          position: absolute;
          height: 1px;
          border-radius: 10px;
          background-color: #4bb8d9;
        }

        .small-sparkle {
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          width: 60%;
        }

        .large-sparkle {
          width: 75%;
          top: -25px;
        }

        &.sparkles-left .large-sparkle {
          left: 25%;
          transform: rotateZ(45deg);
        }
        &.sparkles-right .large-sparkle {
          right: 25%;
          transform: rotateZ(135deg);
        }
      }
    }
  }

  .game-finished-wrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    padding: 10% 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .game-finished-congrats-wrapper {
      position: relative;
      font-size: 2em;
      font-weight: 900;

      .game-finished-butterfly {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-110%, -50%);
        width: 40px;
      }
    }

    .game-finished-four-gems {
      width: 50%;
      padding: 5em 0 2em;
    }

    .gem-found-title {
      color: #4bb8d9;
      padding: 0 5%;
    }

    .gem-found-content {
      padding: 0 5%;
      font-size: 1em;
    }

    .game-dialog-cta-wrapper {
      width: 90%;
      display: flex;
      justify-content: space-between;
      padding-top: 5em;

      .game-dialog-cta {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }

  &:not(.mobile) {
    width: 500px;
    height: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.finished {
      width: 900px;
      height: 60%;
    }

    .gem-found-wrapper {
      .gem-image-wrapper {
        .sparkles {
          .large-sparkle {
            top: -15%;
          }
        }
      }
    }
    .game-finished-wrapper {
      .game-finished-four-gems {
        padding: 2em 0 0.5em;
        width: 20%;
      }
      .game-dialog-cta-wrapper {
        width: 60%;
        padding-top: 2em;
      }
    }
  }
}
</style>