<template>
  <div
    class="splash-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="`
      background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${config.images.background});
    `"
  >
    <div class="splash-content">
      <img class="splash-vca-typo" :src="config.images.typo" alt="vca-typo-splash" />
      <img class="splash-vca-logo" :src="config.images.logo" alt="vca-logo-splash" />
      <div class="splash-catch-phrase">
        DÉCOUVRIR<br/><span>LES ATELIERS DE HAUTE JOAILLERIE</span>
      </div>
      <div class="spacer" />
      <language-selector
        :langs="languages"
        @choose-lang="langChosen"
      />
      <a @click.prevent="startExperience" class="splash-cta">{{ startCTA }}</a>
      <img id="butterfly-blue" class="splash-butterfly" :src="config.images.butterflyBlue" alt="vca-butterfly-blue-splash" />
      <img id="butterfly-yellow" class="splash-butterfly" :src="config.images.butterflyYellow" alt="vca-butterfly-yellow-splash" />
    </div>
  </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector';

export default {
  components: {
    LanguageSelector
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    }
  },

  data: () => ({
    currentLanguage: null,
  }),

  computed: {
    defaultLanguage() {
      return this.languages.find(lang => lang.isDefault === true);
    },
    startCTA() {
      return (this.currentLanguage ? this.currentLanguage.startCTA : this.defaultLanguage.startCTA);
    },
  },

  methods: {
    startExperience() {
      this.$emit(`close`);
    },
    langChosen(language) {
      this.currentLanguage = language;
      this.$emit(`choose-lang`, language);
    }
  },
};
</script>

<style lang="scss" scoped>
.splash-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .splash-content {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
      margin: 1.5em 0;
    }

    .splash-vca-typo {
      width: 50%;
    }

    .splash-vca-logo {
      width: calc(50% * 1/3);
    }

    .splash-catch-phrase {
      font-weight: 300;

      & > span {
        font-weight: 700;
      }
    }

    .spacer {
      width: 10%;
      height: 1px;
      background-color: #000000;
    }

    .language-selector {
      width: 40%;
      font-size: 0.8em;
    }

    .splash-butterfly {
      position: absolute;
      width: 40px;
    }

    #butterfly-blue {
      top: 10%;
      left: 0;
    }

    #butterfly-yellow {
      top: 30%;
      right: 0;
    }

    a {
      padding: 0.75em 2em;
      margin-top: 6em;
      color: #4bb8d9;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      border: 1px solid #4bb8d9;
      border-radius: 4em;
      background-color: rgba(75, 184, 217, 0);
      cursor: pointer;

      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background-color: #4bb8d9;
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &.mobile {
    .splash-content {
      width: 90%;
      font-size: 0.8em;

      .language-selector {
        width: 80%;
      }
    }
  }
}
</style>
