export default [
  {
    name: `splash_screen`,
    startCTA: `COMMENCER`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `Visite 360° de nos ateliers de Haute Joaillerie`,
      },
      {
        name: `movement`,
        text: `Déplacez-vous à l'aide des flèches au sol`,
      },
      {
        name: `hotspots`,
        text: `Cliquez sur les ronds pour en découvrir plus`,
      },
    ],
    gameTutorial: `Collectez des pierres précieuses tout au long de votre visite`,
    // browserTip: `Pour une meilleure expérience, nous vous recommandons d'utiliser le navigateur Google Chrome pour cette visite.`,
    startCTA: `CONTINUER`,
  }
];
