export default {
  name: `map`,
  floorNames: [
    `フロア番号1`,
    `フロア番号2`,
  ],
  firstFloorSpots: [
    {
      name: `sertisseur`,
      label: `セッティング職人`,
    },
    {
      name: `concepteur_cao`,
      label: `CADデザイナー`,
    },
    {
      name: `joaillier`,
      label: `宝飾職人`,
    },
    {
      name: `lapidaire`,
      label: `宝石カット職人`,
    },
  ],
  secondFloorSpots: [
    {
      name: `fondeur`,
      label: `鋳物職人`,
    },
    {
      name: `polisseur`,
      label: `研磨師`,
    },
    {
      name: `diamantaire`,
      label: `ダイヤモンドカット職人`,
    },
    {
      name: `joaillier`,
      label: `宝飾職人`,
    },
  ],
}