export default {
  name: `game`,
  gemFound: {
    congrats: `おめでとうございます`,
    foundMessage: `あなたは、新しい宝石を収集しました！`,
    continueCTA: `続ける`,
  },
  gameWon: {
    congrats: `おめでとうございます`,
    gemsFound: `あなたは貴石をすべて見つけました！`,
    continueCTA:  `続ける`,
    startOverCTA: `ツアーを再スタートする`,
  },
  gemsHover: `宝石収集`,
}
