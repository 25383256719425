<template>
  <div
    class="vca-map"
    :class="{
      visible: open,
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="vca-map-desktop" v-if="!$breakpoints.isMobile">
      <div
        class="vca-map-floor vca-map-first-floor"
        :class="{ visible: currentFloor === 1 }"
      >
        <img :src="map.firstFloorImg" alt="first-floor">
        <button
          class="vca-map-spot"
          v-for="(spot) in map.firstFloorSpots"
          :key="`first-floor-spot-${spot.name}`"
          @click.stop="changeScene(spot.scene)"
          :style="`
            top: ${spot.coords.y}%;
            left: ${spot.coords.x}%;
          `"
        >
          <div class="label">{{ spot.label }}</div>
          <img class="vca-map-spot-hover-image" :src="spot.hoverImg" :alt="`${spot.name}-spot-hover-image`" />
          <div class="spot"><div class="spot-inner" /></div>
        </button>
      </div>
      <div
        class="vca-map-floor vca-map-second-floor"
        :class="{ visible: currentFloor === 2 }"
      >
        <img :src="map.secondFloorImg" alt="second-floor">
        <button
          class="vca-map-spot"
          v-for="(spot) in map.secondFloorSpots"
          :key="`first-floor-spot-${spot.name}`"
          @click.stop="changeScene(spot.scene)"
          :style="`
            top: ${spot.coords.y}%;
            left: ${spot.coords.x}%;
          `"
        >
          <div class="label">{{ spot.label }}</div>
          <img class="vca-map-spot-hover-image" :src="spot.hoverImg" :alt="`${spot.name}-spot-hover-image`" />
          <div class="spot"><div class="spot-inner" /></div>
        </button>
      </div>
      <div class="map-floor-selector">
        <button
          class="map-floor-btn"
          :class="{
            'current-floor': (idx + 1) === currentFloor
          }"
          v-for="(floor, idx) in map.floorNames"
          :key="`map-floor-${idx + 1}`"
          @click.stop="setFloor(idx+1)"
        >
          {{ floor }}
        </button>
      </div>
    </div>
    <div class="vca-map-mobile" v-else>
      <img class="vca-map-logo" :src="map.vcaLogoImg" alt="vca-logo" />
      <div class="map-floor-selector">
        <button
          class="map-floor-btn"
          :class="{
            'current-floor': (idx + 1) === currentFloor
          }"
          v-for="(floor, idx) in map.floorNames"
          :key="`map-floor-${idx + 1}`"
          @click.stop="setFloor(idx+1)"
        >
          {{ floor }}
        </button>
      </div>
      <div class="map-spots-wrapper">
        <div
          class="map-spots-floor-wrapper"
          :class="{
            visible: currentFloor === 1,
          }"
        >
          <button
            class="vca-map-spot"
            v-for="(spot) in map.firstFloorSpots"
            :key="`first-floor-${spot.name}-spot`"
            :style="`
              background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${spot.hoverImg});
            `"
            @click.stop="changeScene(spot.scene)"
          >
            <div class="spot-label">
              {{ spot.label }}
            </div>
            <div class="chevron-wrap">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#fff"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>
            </div>
          </button>
        </div>
        <div
          class="map-spots-floor-wrapper"
          :class="{
            visible: currentFloor === 2,
          }"
        >
          <button
            class="vca-map-spot"
            v-for="(spot) in map.secondFloorSpots"
            :key="`first-floor-${spot.name}-spot`"
            :style="`
              background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${spot.hoverImg});
            `"
            @click.stop="changeScene(spot.scene)"
          >
            <div class="spot-label">
              {{ spot.label }}
            </div>
            <div class="chevron-wrap">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#fff"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>
            </div>
          </button>
        </div>
      </div>
    </div>
    <button class="close-button" @click.stop="close">×</button>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    map: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    currentFloor: 1,
  }),

  computed: {
  },

  methods: {
    close() {
      this.$emit(`close`);
    },
    setFloor(floorNumber) {
      this.currentFloor = floorNumber;
    },
    changeScene(scene) {
      this.$emit(`change-scene`, scene);
      this.$emit(`close-menu`);
    }
  },
};
</script>

<style lang="scss">
.vca-map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #ffffff;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .vca-map-desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .vca-map-floor {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 80%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }

      img {
        width: 100%;
        max-height: 90vh;
      }

      .vca-map-spot {
        position: absolute;
        padding: 1em 4em;
        border-radius: 3em;
        background-color: #4ba8cb;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
        transform: translate(-1.6em, -50%);

        transition: background-color 0.3s ease-in-out;

        .spot {
          width: 2em;
          height: 2em;
          position: absolute;
          top: 50%;
          left: 1.6em;
          transform: translate(-50%, -50%);
          background: transparent;
          outline: 0.4em solid rgba(230,230,230, 0.3);
          border-radius: 2em;

          transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease-in-out;

          .spot-inner {
            width: 1em;
            height: 1em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 3em;
            background-color: #ffffff;
            color: transparent;
            outline: 1px solid;
            outline-offset: 0.4em;

            transition: color 0.3s ease-in-out;
          }
        }

        .vca-map-spot-hover-image {
          position: absolute;
          top: 50%;
          left: 1.7em;
          width: 200px;
          transform: translateY(-50%);
          opacity: 0;
          pointer-events: none;

          transition: opacity 0.3s ease-in-out;
        }

        &:hover {
          background-color: transparent;

          .spot {
            background-color: rgba(75, 168, 203, 0.5);
            width: 2.5em;
            height: 2.5em;

            .spot-inner {
              color: #ffffff;
            }
          }

          .vca-map-spot-hover-image {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    .map-floor-selector {
      position: absolute;
      top: 5%;
      left: 15%;

      .map-floor-btn {
        font-family: 'Montserrat', sans-serif;
        font-size: 2em;
        font-weight: 200;
        margin: 0 0.25em;
        padding-bottom: 0em;
        border-bottom: 2px solid rgba(0, 0, 0, 0);

        transition: padding 0.3s ease-in-out, border-color 0.3s ease-in-out;

        &:hover {
          padding-bottom: 0.1em;
          border-color: #000000;
        }

        &.current-floor {
          font-weight: 700;
          padding-bottom: 0.1em;
          border-color: #000000;
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;
  }
  .vca-map-mobile {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10% 5% 5%;

    .vca-map-logo {
      width: 50%;
    }

    .map-floor-selector {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 30px 0;
      .map-floor-btn {
        margin: 0 0.3em;
        padding: 0 0.2em 0.1em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        border-bottom: 2px solid;
        border-color: rgba(0, 0, 0, 0);
        transition: border-color 0.3s ease-in-out;

        &.current-floor {
          font-weight: 700;
          border-color: #000000;
        }
      }
    }

    .map-spots-wrapper {
      width: 100%;
      flex-grow: 1;
      position: relative;

      .map-spots-floor-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
          pointer-events: all;
        }

        .vca-map-spot {
          margin: 2.5% 0;
          width: 100%;
          padding: 0 5%;
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-family: 'Montserrat', sans-serif;
          font-size: 18px;
          font-weight: 700;
          color: #ffffff;

          .spot-label {
            flex-grow: 1;
          }

          .chevron-wrap {
            height: 24px;
            svg {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .close-button {
    position: absolute;
    top: 3%;
    right: 2%;
    padding: 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #4ba8cb;
    border: none;
    background: none;
    font-size: 30px;
    line-height: 17px;

    transition: color 0.5s ease-in-out;

    &:hover {
      color: #768f9e;
    }
  }

  &:not(.visible) {
    .vca-map-floor {
      pointer-events: none !important;
    }
    .map-spots-floor-wrapper {
      pointer-events: none !important;
    }
  }
}
</style>