export default {
  name: `game`,
  gemFound: {
    congrats: `축하합니다`,
    foundMessage: `새 프레셔스 스톤을 획득했습니다!`,
    continueCTA: `계속하기`,
  },
  gameWon: {
    congrats: `축하합니다.`,
    gemsFound: `모든 스톤을 찾았습니다!`,
    continueCTA:  `계속하기`,
    startOverCTA: `다시 방문하기`,
  },
  gemsHover: `수집할 프레셔스 스톤`,
}
