export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `МАСТЕРСКИЕ ВЫСОКОГО ЮВЕЛИРНОГО ИСКУССТВА`,
    content: [
      `Уже более ста лет Дом демонстрирует свою любовь к драгоценным камням, уникальное ноу-хау и технические достижения.`,
      `Его мастерские, в которых традиционно изготавливаются произведения Высокого ювелирного искусства, находятся в Париже на Вандомской площади.`,
      `Гранильщики, закрепщики и ювелиры работают здесь с самыми драгоценными камнями и металлами.`,
      `Побывав на этой экскурсии, вы получите представление о взыскательности, тщательности и высоком мастерстве, которые необходимы для создания каждого украшения.`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `ГРУППЫ МЕЖДУНАРОДНОГО МАРКЕТИНГА`, // titre de la dialog
    content: [
      `Группа международного маркетинга рука об руку с другими отделами (геммологическим отделом, студией разработки) работает над созданием коллекции.`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `МАСТЕРСКАЯ ВЫСОКОГО ЮВЕЛИРНОГО ИСКУССТВА`,
    content: [
      `Наши украшения изготавливаются в мастерских на основе эскизов.`,
    ],
  },
  {
    name: `creation_studio`,
    title: `СТУДИЯ РАЗРАБОТКИ`,
    content: [
      `Эскиз – это первый этап творческого процесса. В студии разработки художники создают дизайн новых украшений и воплощают в рисунке их форму и цвета.`,
      `После того как эскиз и техническое описание утвердит Николя Бос, рисунок выполняется в технике гуаши, что позволяет более реалистично воспроизвести объемы, цвета и игру света.`,
      `Иногда для того чтобы понять, реально ли с технической точки зрения изготовить данное украшение, требуется выполнить модель.`,
    ],
  },
  {
    name: `stones_expert`,
    title: `СПЕЦИАЛИСТ-ГЕММОЛОГ`,
    content: [
      `Геммолог – это специалист по идентификации и отбору драгоценных и поделочных камней.`,
      `Его задачей является поиск камней, которые соответствуют установленным Домом критериям качества.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.ru_RU.mp4`),
  },
  {
    name: `tin_models`,
    title: `МОДЕЛЬ ИЗ ОЛОВА`,
    content: [
      `Модельер изготавливает модели украшений для расчета их объемов. `,
      `Трехмерные модели выполняются из олова и стразов на основе рисунка гуашью.`,
      `Этот важный этап позволяет наглядно представить объем, продумать технические решения и предварительно рассчитать вес камней.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.ru_RU.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `ПРОФЕССИЯ ПОЛИРОВЩИКА`,
    content: [
      `Полировщик заставляет ювелирные изделия ярко сиять после нескольких этапов полировки.`,
      `Цель его работы – придать блеск как внутренним, так и внешним поверхностям украшения.`,
      `Полировщик обрабатывает изделие с помощью различных приспособлений – полосок ткани, щеток, абразивных паст – на нескольких этапах изготовления: перед закрепкой, после закрепки и после окончательной сборки.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.ru_RU.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `ЧЕМ ПОЛЬЗУЕТСЯ ПОЛИРОВЩИК`,
    content: [
      `Прежде чем на устанавливать на украшение драгоценные камни, все его поверхности нужно отполировать.`,
      `Внутренняя часть изделия обрабатывается при помощи специальных «метелок» из полосок ткани и полировальной пасты. `,
      `Для полировки каждого украшения применяются инструменты подходящей к нему формы и размера.`,
    ],
  },
  {
    name: `quality_controller`,
    title: `КОНТРОЛЕР КАЧЕСТВА `,
    content: [
      `Работа контролера качества заключается в проверке соответствия изделий установленным стандартам качества до того, как они поступят в бутик.`,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `ВЕРСТАК`,
    content: [
      `Столик: У каждого специалиста в мастерской есть свой столик, который используется по-разному в зависимости от того, как мастер привык работать. Он может переходить с одного рабочего места на другое, но его столик все время остается при нем.`,
      `Под каждым столиком имеется кожаный коврик, который позволяет собрать упавшие мелкие элементы или камни, а также материалы, измельченные до порошка.`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `ПРОФЕССИЯ ЮВЕЛИРА`,
    content: [
      `Ювелир изготавливает объемную структуру украшения из драгоценного металла на основе рисунка гуашью и модели.`,
      `Высокое мастерство специалистов Van Cleef & Arpels проявляется в тщательной отделке деталей, заботе об удобстве, разработке оригинальных механизмов и филигранном изготовлении отверстий, пропускающих свет сквозь камни.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.ru_RU.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `ПРОФЕССИЯ ЗАКРЕПЩИКА`,
    content: [
      `Задача закрепщика – вставить драгоценные или поделочные камни в оправу из драгоценного металла таким образом, чтобы они предстали на украшении во всей своей красе.`,
      `При этом удерживающая их оправа должна быть видна как можно меньше.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.ru_RU.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `КИТШТОК`,
    content: [
      `Закрепщик пользуется китштоком, на котором временно фиксирует металлическую оправу, чтобы на ней было удобнее закреплять камни.`,
    ],
  },
  {
    name: `tools_variety`,
    title: `РАЗНООБРАЗИЕ ИНСТРУМЕНТОВ`,
    content: [
      `Закрепщик имеет дело с камнями всех возможных форм и размеров. Поэтому для работы ему требуются самые разнообразные инструменты: штихель, корневертка, китшток и др.`,
      `Иногда закрепщику приходится изготавливать себе собственный инструмент, подходящий для изделия, с которым он работает.`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `ПРОФЕССИЯ ЛИТЕЙЩИКА`,
    content: [
      `Выполнив из зеленого воска модель будущего изделия, ювелир передает ее литейщику для изготовления золотого украшения в его окончательной форме.`,
      `Техника «утраченного воска» заключается в следующем: из гипса делается рабочая форма, которая отправляется в печь, где воск плавится. Затем в форму на его место заливается металл.`,
      `Для изготовления этим методом изделий высокого качества требуется совершенное владение технологией и безупречная точность.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.ru_RU.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `ПРОФЕССИЯ ГРАНИЛЬЩИКА БРИЛЛИАНТОВ`,
    content: [
      `Гранильщик бриллиантов – это специалист по огранке камней, специализирующийся на огранке бриллиантов. В его руках необработанные камни приобретают яркое сияние.`,
      `Алмаз отличается особой твердостью, поэтому только с помощью виртуозного мастерства, отточенной техники и особых навыков можно заставить его засиять в полную силу и создать игру света на его гранях.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.ru_RU.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `НЕВИДИМАЯ ЗАКРЕПКА`,
    content: [
      `Эта виртуозная техника, запатентованная ювелирным Домом в 1933 году, требует одновременно опыта, скрупулезности и огромного терпения.`,
      `Камни обрабатываются один за другим, затем устанавливаются в тонкие золотые желобки, что позволяет полностью скрыть металлическую структуру.`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `ПРОФЕССИЯ ГРАНИЛЬЩИКА`,
    content: [
      `Гранильщик выполняет огранку и полировку драгоценных и поделочных камней.`,
      `Работа гранильщика имеет особенно важное значение при изготовлении украшений с применением невидимой закрепки.`,
      `Этой техникой владеют очень немногие мастера.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.ru_RU.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `ПРОФЕССИЯ РАЗРАБОТЧИКА 3D-МОДЕЛЕЙ`,
    content: [
      `В создании некоторых украшений, особенно самых сложных из них, участвует разработчик 3D-моделей, использующий САПР (Системы Автоматического ПРоектирования). Работая в тесном сотрудничестве с ювелиром, он выполняет цифровой макет в трех измерениях на основе рисунка гуашью. `,
      `Он является своего рода связующим звеном между традиционными методами и современными технологиями.`,
      `На этом этапе рассчитываются объемы деталей, разрабатываются различные механизмы и продумываются решения технических задач, предшествующие изготовлению украшения в мастерской.`,
    ],
    videoCTA: `СМОТРИТЕ ДЕЛОВОЕ ВИДЕО`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.ru_RU.mp4`),
  },
];
