export default [
  {
    name: `splash_screen`,
    startCTA: `开始`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `360°全方位探访高级珠宝工坊`,
      },
      {
        name: `movement`,
        text: `可通过地面箭头进行移动`,
      },
      {
        name: `hotspots`,
        text: `点击圆点即可了解更多内容`,
      },
    ],
    gameTutorial: `游览过程中，别忘了收集珍贵宝石`,
    // browserTip: `为提升用户体验，建议您使用Google Chrome浏览器进行参观`,
    startCTA: `继续`,
  }
];
