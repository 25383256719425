<template>
  <div
    class="vca-menu-wrapper"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="vca-menu-closed" v-show="!open && !$breakpoints.isMobile">
      <button class="vca-menu-entry" @click.stop="toggleMenu">
        <div class="menu-text-wrapper">
          <div class="menu-text">Menu</div>
        </div>
        <div class="menu-icon">
          <svg width="97" viewBox="0 0 97 84" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 7.94359C-1.07897e-06 3.55647 3.5597 7.76971e-06 7.95082 7.38618e-06L89.0492 0C93.4403 0 97 3.55646 97 7.94358C97 12.3307 93.4403 15.8872 89.0492 15.8872L7.95082 15.8872C3.5597 15.8872 0 12.3307 0 7.94359Z" fill="white"/><path d="M0 75.1993C-1.07897e-06 70.8121 3.5597 67.2557 7.95082 67.2557L89.0492 67.2557C93.4403 67.2557 97 70.8121 97 75.1993C97 79.5864 93.4403 83.1428 89.0492 83.1428L7.95082 83.1428C3.5597 83.1428 0 79.5864 0 75.1993Z" fill="white"/><path d="M50.8852 33.8926C46.4941 33.8926 42.9344 37.4491 42.9344 41.8362C42.9344 46.2233 46.4941 49.7798 50.8852 49.7798L89.0492 49.7798C93.4403 49.7798 97 46.2233 97 41.8362C97 37.4491 93.4403 33.8926 89.0492 33.8926L50.8852 33.8926Z" fill="white"/></svg>
        </div>
      </button>
    </div>
    <div class="vca-menu-open" v-show="open || $breakpoints.isMobile">
      <button class="vca-menu-close-btn" @click.stop="toggleMenu" v-show="!$breakpoints.isMobile">×</button>
      <button
        class="vca-menu-entry"
        v-for="(btn, idx) in menuButtons"
        :key="`menu-btn-${idx}`"
        :class="{
          'inner-menu-entry': idx < menuButtons.length - 1,
        }"
        v-show="!$breakpoints.isMobile || (btn.name === `carte_ateliers`)"
        @click.stop="buttonAction(btn.action)"
      >
        <div class="menu-text-wrapper">
          <div class="menu-text">{{ btn.text }}</div>
        </div>
        <div class="menu-icon" v-html="btn.icon"></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    }
  },

  computed: {
    menuButtons() {
      return this.menu?.entries || []
    },
  },

  methods: {
    toggleMenu() {
      this.$emit(`toggle-menu`);
    },
    buttonAction(action) {
      switch (action) {
        case `open-map`:
          this.openMap();
          break;
        case `toggle-fullscreen`:
          this.toggleFullscreen();
          break;
      }
    },
    openMap() {
      this.$emit(`open-map`);
    },
    toggleFullscreen() {
      this.$emit(`toggle-fullscreen`);
    },
  },
};
</script>

<style lang="scss" scoped>
.vca-menu-wrapper {
  position: absolute;
  top: 5%;
  right: 2%;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .vca-menu-open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .vca-menu-close-btn {
      padding-right: 20px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      color: #4ba8cb;
      border: none;
      background: none;
      font-size: 30px;
      line-height: 17px;

      transition: color 0.5s ease-in-out;

      &:hover {
        color: #768f9e;
      }
    }

    .inner-menu-entry {
      margin: 1.5em 0;
    }
  }
}
</style>

<style lang="scss">
.vca-menu-entry {
  display: flex;
  align-items: center;
  padding: 0.3em 1.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #4bb7d9;
  border: none;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease-in-out;

  .menu-icon {
    width: 25px;
    margin-left: 1em;
    svg {
      width: 100%;
      &, & path {
        transition: fill 0.3s ease-in-out;
      }
    }
  }

  .menu-text-wrapper {
    overflow-x: clip;
    width: auto;
    transition: width 0.3s ease-in-out;
    .menu-text {
      opacity: 0;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);

    svg, path {
      fill: #4bb7d9;
    }
    .menu-text-wrapper {
      width: auto;
      .menu-text {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.vca-menu-wrapper.mobile {
  .vca-menu-entry {
    &.inner-menu-entry {
      margin: 0;
    }
    .menu-text-wrapper {
      display: none;
    }
    .menu-icon {
      margin: 0;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
