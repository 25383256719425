export default {
  name: `game`,
  gemFound: {
    congrats: `FÉLICITATIONS !`,
    foundMessage: `VOUS AVEZ COLLECTÉ UNE PIERRE PRÉCIEUSE`,
    continueCTA: `CONTINUER`,
  },
  gameWon: {
    congrats: `BRAVO`,
    gemsFound: `VOUS AVEZ TROUVÉ TOUTES LES PIERRES !`,
    continueCTA:  `CONTINUER`,
    startOverCTA: `RECOMMENCER LA VISITE`,
  },
  gemsHover: `PIERRES PRÉCIEUSES À COLLECTER`,
}
