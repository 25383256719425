export default [
  {
    name: `place_vendome_2`,
    label: `Discover Place Vendôme`,
  },
  {
    name: `maquettes_etain`,
    label: `Open the door to the Ateliers`,
  },
  {
    name: `entree_ateliers`,
    label: `Meet the Mains d'Or`,
  },
  {
    name: `polisseur`,
    label: `The Polisher`,
  },
  {
    name: `joaillier_1`,
    label: `The Jeweler`,
  },
  {
    name: `joaillier_2`,
    label: `The Jeweler`,
  },
  {
    name: `sertisseur`,
    label: `The Gem-Setter`,
  },
  {
    name: `fondeur`,
    label: `The Caster`,
  },
  {
    name: `diamantaire`,
    label: `The Diamond-Cutter`,
  },
  {
    name: `lapidaire_1`,
    label: `The Lapidary`,
  },
  {
    name: `lapidaire_2`,
    label: `The Lapidary`,
  },
  {
    name: `concepteur_cao`,
    label: `The CAD designer`,
  },
];