export default {
  name: `game`,
  fourGemsImg: require(`@/assets/common/four_gems.png`),
  butterfly: require(`@/assets/common/papillon_bleu.png`),
  gemsFull: {
    green: require(`@/assets/game/green.png`),
    blue: require(`@/assets/game/blue.png`),
    red: require(`@/assets/game/red.png`),
    white: require(`@/assets/game/white.png`),
  },
  gemsEmpty: {
    green: require(`@/assets/game/green_empty.png`),
    blue: require(`@/assets/game/blue_empty.png`),
    red: require(`@/assets/game/red_empty.png`),
    white: require(`@/assets/game/white_empty.png`),
  },
}