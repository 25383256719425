export default [
  {
    name: `splash_screen`,
    startCTA: `البداية`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `جولة 360 درجة في ورش عمل المجوهرات الراقية لدينا`,
      },
      {
        name: `movement`,
        text: ` تحرك باستخدام الأسهم على الأرض`,
      },
      {
        name: `hotspots`,
        text: ` انقروا على الدوائر لمعرفة المزيد`,
      },
    ],
    gameTutorial: `اجمعوا الأحجار الكريمة طوال زيارتكم`,
    // browserTip: `للحصول على تجربة أفضل نوصي باستخدام متصفح Google Chrome لهذه الجولة.`,
    startCTA: `متابعة`,
  }
];
