export default [
  {
    name: `place_vendome_2`,
    label: `방돔 광장 둘러보기`,
  },
  {
    name: `maquettes_etain`,
    label: `워크샵 입장`,
  },
  {
    name: `entree_ateliers`,
    label: `맹도드를 만나다`,
  },
  {
    name: `polisseur`,
    label: `폴리셔`,
  },
  {
    name: `joaillier_1`,
    label: `주얼러`,
  },
  {
    name: `joaillier_2`,
    label: `주얼러`,
  },
  {
    name: `sertisseur`,
    label: `스톤 세터`,
  },
  {
    name: `fondeur`,
    label: `제련사`,
  },
  {
    name: `diamantaire`,
    label: `다이아몬드 세공사`,
  },
  {
    name: `lapidaire_1`,
    label: `보석 세공사`,
  },
  {
    name: `lapidaire_2`,
    label: `보석 세공사`,
  },
  {
    name: `concepteur_cao`,
    label: `CAD 디자이너`,
  },
];