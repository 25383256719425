export default [
  {
    name: `place_vendome_2`,
    label: `探索芳登广场`,
  },
  {
    name: `maquettes_etain`,
    label: `推开工作坊的大门`,
  },
  {
    name: `entree_ateliers`,
    label: `遇见金手指大师`,
  },
  {
    name: `polisseur`,
    label: `抛光师`,
  },
  {
    name: `joaillier_1`,
    label: `珠宝师`,
  },
  {
    name: `joaillier_2`,
    label: `珠宝师`,
  },
  {
    name: `sertisseur`,
    label: `镶嵌师`,
  },
  {
    name: `fondeur`,
    label: `熔炼师`,
  },
  {
    name: `diamantaire`,
    label: `钻石切割师`,
  },
  {
    name: `lapidaire_1`,
    label: `宝石切割师`,
  },
  {
    name: `lapidaire_2`,
    label: `宝石切割师`,
  },
  {
    name: `concepteur_cao`,
    label: `CAD建模师`,
  },
];