export default {
  name: `tutorial`,
  title: `튜토리얼`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `360° 이미지를 보려면 마우스 클릭 후 드래그하세요.`
    },
    {
      name: `arrows_movement`,
      text: `바닥에 표시된 화살표를 따라 이동하세요.`,
    },
    {
      name: `hotspots`,
      text: `자세히 알고 싶다면 동그라미를 클릭하세요.`,
    },
  ],
  continueCTA: `건너뛰기`,
}