export default {
  name: `tutorial`,
  title: `TUTORIEL`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `Cliquez et glissez votre doigt pour regarder autour de la photo 360`
    },
    {
      name: `arrows_movement`,
      text: `Déplacez-vous en cliquant sur les flèches au sol`,
    },
    {
      name: `hotspots`,
      text: `Cliquez sur les ronds pour en découvrir plus`,
    },
  ],
  continueCTA: `CONTINUER`,
}
