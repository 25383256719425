export default [
  {
    name: `splash_screen`,
    startCTA: `시작하기`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `360° 이미지로 하이 주얼리 아틀리에`,
      },
      {
        name: `movement`,
        text: `바닥에 표시된 화살표를 따라 이동하세요.`,
      },
      {
        name: `hotspots`,
        text: `자세히 알고 싶다면 동그라미를 클릭하세요.`,
      },
    ],
    gameTutorial: `방문하는 동안 프레셔스 스톤을 모으세요.`,
    // browserTip: `최고의 경험을 위해 구글 크롬 사용을 권장합니다.`,
    startCTA: `계속하기`,
  }
];
