export default {
  name: `game`,
  gemFound: {
    congrats: `تهانينا`,
    foundMessage: `لقد قمتم بجمع حجر كريم جديد!`,
    continueCTA: `متابعة`,
  },
  gameWon: {
    congrats: `أحسنتم`,
    gemsFound: ` لقد وجدتم كافة الأحجار الكريمة!`,
    continueCTA:  `متابعة `,
    startOverCTA: ` بدء الجولة من جديد`,
  },
  gemsHover: `الأحجار الكريمة المراد جمعها`,
}
