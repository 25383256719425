
export default [
  {
    key: `fr_FR`,
    shortLabel: `FR`,
    label: `French`,
    icon: require(`@/assets/icons/flags/french.svg`),
    isDefault: true,
    startCTA: `COMMENCER`,
  },
  {
    key: `jp_JP`,
    shortLabel: `JP`,
    label: `Japanese`,
    icon: require(`@/assets/icons/flags/japanese.svg`),
    isDefault: false,
    startCTA: `スタートする`,
  },
  {
    key: `en_US`,
    shortLabel: `EN`,
    label: `English`,
    icon: require(`@/assets/icons/flags/english.svg`),
    isDefault: false,
    startCTA: `START`,
  },
  {
    key: `ar_AR`,
    shortLabel: `AR`,
    label: `Arabic`,
    icon: require(`@/assets/icons/flags/arabic.svg`),
    isDefault: false,
    startCTA: `البداية`,
  },
  {
    key: `zh_CN`,
    shortLabel: `CN`,
    label: `Chinese`,
    icon: require(`@/assets/icons/flags/chinese.svg`),
    isDefault: false,
    startCTA: `开始`,
  },
  {
    key: `ko_KR`,
    shortLabel: `KR`,
    label: `Korean`,
    icon: require(`@/assets/icons/flags/korean.svg`),
    isDefault: false,
    startCTA: `시작하기`,
  },
  {
    key: `ru_RU`,
    shortLabel: `RU`,
    label: `Russian`,
    icon: require(`@/assets/icons/flags/russian.svg`),
    isDefault: false,
    startCTA: `НАЧАТЬ`,
  },
]
