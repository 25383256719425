<template>
  <div class="language-selector">
    <div class="lang-selector-select" :class="{ 'lang-selected': selectedLang }" @click.prevent="toggleSelector">
      <div class="selected-language">
        <template v-if="selectedLang">
          <div class="language-flag" :style="`background-image: url(${selectedLang.icon});`" />
          <p class="language-label">{{ selectedLang.label }}</p>
        </template>
        <template v-else>
          CHOOSE LANGUAGE
        </template>
      </div>
      <div class="chevron-wrapper">
        <img class="lang-selector-chevron" :class="{ open: isSelectorOpened }" :src="chevronIcon" alt="select-chevron" />
      </div>
    </div>
    <div class="lang-selector-options-wrapper">
      <div class="lang-selector-options-container" :class="{ open: isSelectorOpened }">
        <div
          class="lang-selector-option"
          v-for="lang in langs"
          :key="`vca-language-${lang.key}`"
          @click.prevent="selectLang(lang.key)"
        >
          <div class="language-flag" :style="`background-image: url(${lang.icon});`" />
          <p class="language-label">{{ lang.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    langs: {
      type: Array,
      required: true,
    }
  },

  data: () => ({
    isSelectorOpened: false,
    selectedLangKey: null,
  }),

  computed: {
    defaultLang() {
      return this.langs.find(lang => lang.isDefault === true);
    },
    selectedLang() {
      const selectedLang = this.langs.find(lang => lang.key === this.selectedLangKey) || null;

      if (selectedLang) {
        this.$emit(`choose-lang`, selectedLang);
      }

      return selectedLang;
    },
    chevronIcon() {
      return require(`@/assets/icons/chevron_down_black.svg`) || ``;
    },
  },

  mounted() {
    if (!this.selectedLangKey) {
      this.selectedLangKey = this.defaultLang.key;
    }
  },

  methods: {
    toggleSelector() {
      if (this.isSelectorOpened) {
        this.closeSelector();
      } else {
        this.openSelector();
      }
    },
    openSelector() {
      this.isSelectorOpened = true;
    },
    closeSelector() {
      this.isSelectorOpened = false;
    },
    selectLang(key) {
      this.selectedLangKey = key;
      this.closeSelector();
    }
  },
};
</script>

<style lang="scss">
.language-selector {
  position: relative;

  .lang-selector-select,
  .lang-selector-option {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    border: 1px solid #dedede;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #f9f9f9;
    }
  }

  .language-flag {
    width: 1em;
    height: 1em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 0px 2px rgba(200, 200, 200, 0.5);
    border-radius: 100px;
  }

  .language-label {
    margin: 0;
    margin-left: 1em;
  }

  .lang-selector-select {
    position: relative;
    justify-content: space-between;

    .selected-language {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .chevron-wrapper {
      width: 1em;

      .lang-selector-chevron {
        width: 100%;
        transition: transform 0.3s ease;

        &.open {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  .lang-selector-options-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    transform: translateY(100%);
    pointer-events: none;

    .lang-selector-options-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      pointer-events: none;
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: translateY(0);
        pointer-events: all;
      }

      .lang-selector-option {
        width: 100%;
        border-top: none;

        .language-flag {
          width: 1em;
          height: 1em;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          box-shadow: 0px 0px 0px 2px rgba(200, 200, 200, 0.5);
          border-radius: 100px;
        }

        .language-label {
          margin: 0;
          margin-left: 1em;
        }
      }
    }
  }
}
</style>