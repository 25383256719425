<template>
  <div
    class="vca-loading-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="`
      background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${config.images.background});
    `"
  >
    <div class="loading-screen-content">
      <img class="vca-logo-typo" :src="config.images.typo" alt="vca-logo-typo" />
      <img class="vca-logo" :src="config.images.logo" alt="vca-logo" />
      <img class="vca-butterfly" :src="config.images.butterfly" alt="vca-butterfly-yellow" />
      <div class="loading-bar-wrapper">
        <div class="loading-bar-container">
          <div class="loading-bar" :style="`width: ${progress}%;`" />
        </div>
        <div class="loading-progress">{{ progressLabel }}%</div>
        <div class="loading-text">{{ config.loadingText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: false,
      default: () => 0,
    }
  },

  computed: {
    progressLabel() {
      return Math.ceil(this.progress);
    },
  },
};
</script>

<style lang="scss" scoped>
$loading-bar-height: 7px;
$loading-bar-border-radius: 14px;

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.vca-loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .loading-screen-content {
    width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .vca-logo-typo {
      width: 50%;
    }

    .vca-logo {
      width: calc(50% * 1/3);
    }

    .vca-butterfly {
      width: 50px;
    }

    .vca-logo-typo,
    .vca-logo,
    .vca-butterfly {
      margin: 1em 0;
    }

    .loading-bar-wrapper {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #4bb8d9;
      font-size: 12px;

      .loading-bar-container {
        margin-top: 6em;
        position: relative;
        width: 100%;
        height: $loading-bar-height;
        border-radius: $loading-bar-border-radius;
        background-color: hsl(0, 0%, 92%);

        .loading-bar {
          position: absolute;
          top: 0;
          left: 0;
          height: $loading-bar-height;
          border-radius: $loading-bar-border-radius;
          background-color: #4bb8d9;
          transition: width 0.1s ease-in-out;
        }
      }

      .loading-progress {
        margin: 1em 0;
      }

      .loading-text {
        @include keyframes(blink) {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @include animate(blink, 1.5s, ease, infinite)
      }
    }
  }

  &.mobile {
    .loading-screen-content {
      width: 90%;

      .vca-logo-typo {
        width: 70%;
      }

      .vca-logo {
        width: calc(70% * 1/3);
      }

      .vca-butterfly {
        margin: 5em 0;
      }

      .loading-bar-wrapper {
        width: 60%;
      }
    }
  }
}
</style>