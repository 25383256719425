export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `LES ATELIERS DE HAUTE JOAILLERIE`,
    content: [
      `Depuis plus d’un siècle, la Maison mêle amour des pierres, savoir-faire unique et prouesses techniques.`,
      `Les ateliers historiques, consacrés à la création de pièces de Haute Joaillerie, sont situés place Vendôme, à Paris.`,
      `En collaboration avec le département Pierres, lapidaires, polisseurs, diamantaires, sertisseurs et joailliers travaillent les pierres et les matières les plus précieuses.`,
      `Venez découvrir à travers cette visite l’exigence, la minutie et l’excellence nécessaires à la réalisation de chaque pièce.`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `LES ÉQUIPES MARKETING INTERNATIONAL`, // titre de la dialog
    content: [
      `L’équipe Marketing International travaille main dans la main avec les différents services (pierres, studio) pour la genèse d’une collection.`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `L'ATELIER DE HAUTE JOAILLERIE`,
    content: [
      `Nos créations sont fabriquées à partir des dessins dans nos ateliers.`,
      `Une pièce de Haute Joaillerie est une œuvre collective et nous allons voir quels sont les différents métiers impliqués dans la création.`,
    ],
  },
  {
    name: `creation_studio`,
    title: `LE STUDIO DE CRÉATION`,
    content: [
      `Le dessin est la première étape du processus créatif. Au Studio, les dessinateurs imaginent de nouvelles créations et en retranscrivent les formes et les couleurs. `,
      `Après confirmation technique et validation de Nicolas BOS, le dessin est transformé en gouaché, celui-ci permet de rendre les volumes, les couleurs et les jeux de lumière plus réalistes.`,
      `Parfois une maquette est nécessaire pour se rendre compte de la faisabilité technique des pièces.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/dessinatrice/dessinatrice.fr_FR.mp4`),
  },
  {
    name: `stones_expert`,
    title: `L'EXPERT PIERRES`,
    content: [
      `Le gemmologue est expert dans l’identification et la sélection des pierres précieuses ou ornementales.`,
      `Il a pour mission de trouver les gemmes correspondant aux critères d’excellence de la Maison.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.fr_FR.mp4`),
  },
  {
    name: `tin_models`,
    title: `LA MAQUETTE EN ÉTAIN`,
    content: [
      `Le maquettiste conçoit des maquettes des créations afin d’en étudier les volumes. `,
      `À partir du dessin, une maquette est réalisée avec de l’étain et des strass pour interpréter en trois dimensions le gouaché de la pièce. `,
      `Cette étape essentielle permet de visualiser le volume, d’anticiper des solutions techniques et de faire une première estimation du poids des pierres.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.fr_FR.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `LE MÉTIER DE POLISSEUR`,
    content: [
      `Le polisseur permet de révéler tout l’éclat des créations joaillières à travers les étapes de polissage.`,
      `Le polissage a pour objectif de faire briller toutes les surfaces intérieures et extérieures de la pièce. `,
      `À l’aide de ses outils – fils, brosses et pâtes abrasives – le polisseur intervient à différentes étapes de la création : avant le serti, après le serti et après le montage final.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.fr_FR.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `LES OUTILS DU POLISSEUR`,
    content: [
      `Chaque pièce est polie sur toutes ses faces avant d’être sertie.`,
      `L'intérieur des pièces est poli avec du fil de Jaconas et avec l'aide de pâte à polir.`,
      `Chaque polissage nécessite des outils de différentes tailles et formes pour s’adapter à chaque pièce.`,
    ],
  },
  {
    name: `quality_controller`,
    title: `LE CONTRÔLEUR QUALITÉ`,
    content: [
      `Le contrôleur qualité a pour mission de s’assurer de la conformité et de la qualité des pièces avant qu’elles soient disponibles en boutique.`,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `L'ÉTABLI`,
    content: [
      `La cheville : Chaque artisan dispose d'une cheville à son atelier qu'il utilise différemment en fonction de sa manière de travailler. Les artisans peuvent changer de poste au cours de leur activité mais ils conservent avec eux leur cheville.`,
      `Peau en cuir : placée sous chaque établi, celle-ci permet de récupérer, à la fois les petits éléments ou pierres, ainsi que les matériaux réduits à l'état de poussière.`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `LE MÉTIER DE JOAILLIER`,
    content: [
      `Travaillant le métal précieux, le joaillier réalise la structure en volume de la pièce à partir du gouaché et de la maquette.`,
      `Le savoir-faire d’excellence des artisans de Van Cleef & Arpels se reflète dans l’attention portée aux détails et au confort, l’ingéniosité des mécanismes ou encore la finesse des mises à jour qui laisseront passer la lumière à travers les pierres.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.fr_FR.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `LE MÉTIER DE SERTISSEUR`,
    content: [
      `Le rôle du sertisseur est d’enchâsser les pierres précieuses ou ornementales dans le métal précieux et de les mettre en valeur sur les pièces de joaillerie. `,
      `Elles doivent ainsi être maintenues tout en faisant disparaître le plus possible la monture.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.fr_FR.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `LE BÂTON DE SERTI`,
    content: [
      `Le sertisseur utilise un bâton de serti afin de fixer temporairement l’armature en métal pour avoir une meilleure prise lors de son sertissage. `,
    ],
  },
  {
    name: `tools_variety`,
    title: `VARIÉTÉ DES OUTILS`,
    content: [
      `Le sertisseur travaille sur des pierres de toutes formes et de toutes tailles. Il possède donc un panel d’outils très variés pour répondre à ses besoins : échoppes, perloirs, bâton de sertisseur, ...`,
      `Il arrive que le sertisseur crée son propre outil pour qu’il soit adapté à la pièce sur laquelle il travaille.`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `LE MÉTIER DE FONDEUR`,
    content: [
      `Lorsque le joaillier termine la sculpture en cire verte d’une maquette de pièce, il la confie au fondeur pour qu’il puisse lui donner sa forme finale en or. `,
      `La technique de la « fonte à cire perdue » consiste à réaliser un moule en plâtre de la réalisation, qui sera ensuite passé au four afin de faire fondre la cire et d’y couler le métal. `,
      `Ce processus nécessite une grande maîtrise et une précision extrême pour assurer la qualité des créations.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.fr_FR.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `LE MÉTIER DE DIAMANTAIRE`,
    content: [
      `Expert dans la taille des pierres et plus particulièrement spécialisé dans la taille du diamant, le diamantaire en révèle l’éclat intense à partir de pierres brutes.`,
      `Pierre d’une dureté exceptionnelle, le diamant nécessite un savoir-faire, des techniques et une expertise spécifiques afin de révéler toute l’intensité de son éclat et de ses feux.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.fr_FR.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `LE SERTI MYSTÉRIEUX`,
    content: [
      `Véritable prouesse brevetée en 1933 par la Maison, cette technique de serti requiert à la fois expérience, minutie et une grande patience. `,
      `Les gemmes sont taillées une à une puis insérées sur de fins rails d’or afin de faire disparaître totalement la structure en métal.`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `LE MÉTIER DE LAPIDAIRE`,
    content: [
      `Le lapidaire taille et polit les pierres précieuses et ornementales.`,
      `Le savoir-faire du lapidaire est notamment impliqué dans la réalisation des pièces en Serti Mystérieux.`,
      `Seule une poignée d'artisans maîtrisent ce savoir-faire.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.fr_FR.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `LE MÉTIER DE MODÉLISTE CAO`,
    content: [
      `Pour certaines pièces, notamment les plus complexes, le modéliste CAO (Conception Assistée par Ordinateur) travaille en étroite collaboration avec le joaillier pour imaginer une représentation numérique en trois dimensions des créations à partir de leur gouaché. `,
      `Il fait le lien entre les savoir-faire traditionnels et les technologies modernes. `,
      `Cette étape permet d’étudier les volumes des pièces, d’en imaginer les différents mécanismes et d’en anticiper les défis techniques avant leur réalisation à l’atelier.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.fr_FR.mp4`),
  },
];
