export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    img: require(`@/assets/dialogs/images/atelier_vca.png`),
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    img: require(`@/assets/dialogs/images/international_marketing_teams.png`),
  },
  {
    name: `high_jewellery`,
    img: require(`@/assets/dialogs/images/intro_jeweller.png`),
  },
  {
    name: `creation_studio`,
    img: require(`@/assets/dialogs/images/creation_studio.png`),
  },
  {
    name: `stones_expert`,
    img: require(`@/assets/dialogs/images/stones_expert.png`),
    video: require(`@/assets/dialogs/videos/stones_expert.mp4`),
  },
  {
    name: `tin_models`,
    img: require(`@/assets/dialogs/images/tin_modeller.png`),
    video: require(`@/assets/dialogs/videos/tin_modeller.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    img: require(`@/assets/dialogs/images/profession_polisher.png`),
    video: require(`@/assets/dialogs/videos/profession_polisher.mp4`),
  },
  {
    name: `polishing_tools`,
    img: require(`@/assets/dialogs/images/polishing_tools.png`),
  },
  {
    name: `quality_controller`,
    img: require(`@/assets/dialogs/images/quality_control.png`),
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    img: require(`@/assets/dialogs/images/jeweller_workbench.png`),
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    img: require(`@/assets/dialogs/images/atelier_vca_jeweller.png`),
    video: require(`@/assets/dialogs/videos/profession_jeweller.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    img: require(`@/assets/dialogs/images/profession_crimper.png`),
    video: require(`@/assets/dialogs/videos/profession_crimper.mp4`),
  },
  {
    name: `crimping_stick`,
    img: require(`@/assets/dialogs/images/crimping_stick.png`),
  },
  {
    name: `tools_variety`,
    img: require(`@/assets/dialogs/images/tools_variety.png`),
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    img: require(`@/assets/dialogs/images/profession_founder.png`),
    video: require(`@/assets/dialogs/videos/profession_founder.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    img: require(`@/assets/dialogs/images/profession_diamond_cutter.png`),
    video: require(`@/assets/dialogs/videos/profession_diamond_cutter.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    img: require(`@/assets/dialogs/images/mysterious_crimp.png`),
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    img: require(`@/assets/dialogs/images/profession_lapidary.jpg`),
    video: require(`@/assets/dialogs/videos/profession_lapidary.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    img: require(`@/assets/dialogs/images/profession_cao_modeller.png`),
    video: require(`@/assets/dialogs/videos/profession_cao_modeler.mp4`),
  },
];
