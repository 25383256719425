export default {
  name: `game`,
  gemFound: {
    congrats: `恭喜`,
    foundMessage: `您收集到一颗珍贵宝石！`,
    continueCTA: `继续`,
  },
  gameWon: {
    congrats: `恭喜`,
    gemsFound: `您已集齐所有宝石！`,
    continueCTA:  `继续`,
    startOverCTA: `重启旅途`,
  },
  gemsHover: `收集珍贵宝石`,
}
