export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `ハイジュエリーのアトリエ`,
    content: [
      `メゾンは1世紀以上にわたり、宝石への情熱を注き、独自のサヴォアフェールと優れた技術力を培ってきました。`,
      `ハイジュエリーの制作に捧げられた歴史的なアトリエは、パリのヴァンドーム広場に位置します。`,
      `アトリエでは、宝石カット職人 、セッティング職人、宝飾職人が最も貴重な宝石と金属に取り組んでいます。`,
      `このツアーを通して、それぞれの作品を実現するために必要な精密さと卓越性をご覧ください。`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `インターナショナル マーケティング チーム`, // titre de la dialog
    content: [
      `インターナショナル マーケティング チームは、各部門（宝石部門、クリエーションスタジオ）とお互いに協力し合ってコレクション制作に取り組んでいます。`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `ハイジュエリーのアトリエ`,
    content: [
      `ヴァン クリーフ&アーペルの作品は、アトリエでデザインから作られます。`,
      `ハイジュエリー作品は共同作品です。制作に携わる様々な職業をこれより見ていきましょう。`,
    ],
  },
  {
    name: `creation_studio`,
    title: `クリエーションスタジオ`,
    content: [
      `デザイン画は創作プロセスの第一歩です。デザイナーがスタジオで新しい作品を想像し、その形や色彩を描き出します。`,
      `ニコラ・ボスによる技術的な確認・確定の後、デザインをグワッシュ画で描きます。グワッシュ画により、ボリューム感や色彩、さらにリアルな光の陰影が分かります。`,
      `ジュエリーの技術的な実現可能性を確認するために、モックアップが制作されます。`,
    ],
  },
  {
    name: `stones_expert`,
    title: `ストーンエキスパート`,
    content: [
      `ストーンエキスパートは、プレシャスストーンまたはオーナメンタルストーン選定の専門家です。`,
      `彼らの使命は、メゾンの厳格な基準を満たす宝石を探し出すことです。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.jp_JP.mp4`),
  },
  {
    name: `tin_models`,
    title: `錫モックアップ`,
    content: [
      `モデリング職人は作品のボリュームを確認するために、作品のモックアップを制作します。`,
      `グワッシュ画をもとに、錫とラインストーンを使い3次元で表現します。`,
      `作品のボリューム感がつかめ、技術的解決策が予測でき、石の重さの第1推定が可能となる重要なステップです。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.jp_JP.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `研磨師の仕事`,
    content: [
      `研磨師は、研磨という過程を経てジュエリーの輝きを最大限に引き出します。`,
      `研磨の目的は、表面・裏側すべてを輝かせることです。`,
      `研磨師は、糸、ブラシ、研磨パッドなどのツールを用いてセッティング前、セッティング後、最終組立て後の様々な制作工程で研磨をします。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.jp_JP.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `研磨師のツール`,
    content: [
      `それぞれの作品は、セッティング前にすべての面を研磨しています。`,
      `作品の内側は、研磨剤を付けた糸で磨きます。`,
      `研磨にはそれぞれの作品に合ったサイズや形状のツールが必要です。`,
    ],
  },
  {
    name: `quality_controller`,
    title: `品質管理者 `,
    content: [
      `品質管理者の役割は、製品がブティックに出る前に、その適合性と品質を確認することです。`,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `作業台`,
    content: [
      `ペグ ：それぞれの職人は、アトリエにペグを備えており、作業に応じて使い分けをしています。職人はその作業中に職場が変わっても、自分のペグをそのまま持ち続けます。`,
      `革のポケット：各作業台の下についたポケットに、小さなピースや石、埃状態になった素材をキャッチします。`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `宝飾職人の仕事`,
    content: [
      `宝飾職人はグワッシュ画とモックアップから、貴金属で作品の3次元構造を作ります。`,
      `ヴァン クリーフ&アーペルの職人の卓越したサヴォアフェールは、ディテールや着け心地へのこだわり、機構の工夫、石に光を通すミザジュールの精巧さなどに反映されています。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.jp_JP.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `セッティング職人の仕事`,
    content: [
      `セッティング職人の役割は、プレシャスストーンやオーナメンタルストーンを貴金属にセットし、作品の美しさを際立たせることです。 `,
      `セッティング職人は、フレームをできるだけ見えないように石を固定する必要があります。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.jp_JP.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `セッティングバー`,
    content: [
      `セッティング職人は、セッティングバーを使って金属フレームを仮固定し、セッティング時にしっかりと支えられるようにします。`,
    ],
  },
  {
    name: `tools_variety`,
    title: `多様なツール`,
    content: [
      `セッティング職人は、あらゆる形や大きさの石に対応します。そのため、たがね、ビーダー、セッティングバーなど、必要に応じて様々な道具を揃えています。`,
      `セッティング職人は、制作する作品に合わせて独自のツールを制作することもあります。`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `鋳物職人の仕事`,
    content: [
      `宝飾職人が緑色のワックスで作品のモックアップを完成させると、それを鋳物職人に渡して最終的な金の形に仕上げます。`,
      `「ロストワックス技法」は、作品の石膏型を作り、それを炉に入れて蝋を溶かし、そこに金属を流し込む技法です。`,
      `この工程では、作品の品質を確保するために、非常に熟練した技術と高い精度が要求されます。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.jp_JP.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `ダイヤモンドカット職人の仕事`,
    content: [
      `宝石カットの中でも特にダイヤモンドカットの専門家であるダイヤモンドカット職人は、原石から美しい輝きを引き出します。`,
      `ダイヤモンドは素晴らしい硬度を持つ石であり、その輝きと煌めきの強さを最大限に引き出すためにはサヴォアフェール、技術、専門知識が必要です。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.jp_JP.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `ミステリーセット`,
    content: [
      `メゾンが1933年に特許を取得したこの技法は、長年の経験と細心の注意、そして忍耐力を必要とします。`,
      `宝石を1つひとつカットし、金の細いレールにはめ込み、金属の構造が完全に見えなくなるようにします。`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `宝石カット職人の仕事`,
    content: [
      `宝石カット職人は、プレシャスストーンとオーナメンタルストーンをカット・研磨します。`,
      `宝石カット職人のサヴォアフェールは、ミステリーセットの作品制作に表れています。`,
      `このサヴォアフェールを習得しているのは、ほんの一握りの職人だけです。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.jp_JP.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `CADモデリング職人の仕事`,
    content: [
      `特に複雑な作品では、CAD（コンピューター支援設計）モデリング職人が宝飾職人と綿密に連携して作品のグワッシュ画から3次元デジタル表示を想像します。`,
      `CADモデリング職人は、伝統的なサヴォアフェールと近代的なテクノロジーを結び付けています。`,
      `この段階で、作品のボリューム感を確認し、その様々なメカニズムを想像し、アトリエで作品の制作に入る前に技術的困難を予測することができます。`,
    ],
    videoCTA: `ビジネスビデオをご覧ください`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.jp_JP.mp4`),
  },
];
