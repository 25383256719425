export default {
  name: `tutorial`,
  title: `إرشادات الاستعمال`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `انقروا واسحبوا الفأرة للنظر حول الصورة بزاوية 360 درجة`
    },
    {
      name: `arrows_movement`,
      text: `تحرّكوا باستخدام الأسهم على الأرض`,
    },
    {
      name: `hotspots`,
      text: `انقروا على الدوائر لمعرفة المزيد`,
    },
  ],
  continueCTA: `تخطّي`,
}