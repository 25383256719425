var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vca-map",class:{
    visible: _vm.open,
    mobile: _vm.$breakpoints.isMobile,
  }},[(!_vm.$breakpoints.isMobile)?_c('div',{staticClass:"vca-map-desktop"},[_c('div',{staticClass:"vca-map-floor vca-map-first-floor",class:{ visible: _vm.currentFloor === 1 }},[_c('img',{attrs:{"src":_vm.map.firstFloorImg,"alt":"first-floor"}}),_vm._l((_vm.map.firstFloorSpots),function(spot){return _c('button',{key:("first-floor-spot-" + (spot.name)),staticClass:"vca-map-spot",style:(("\n          top: " + (spot.coords.y) + "%;\n          left: " + (spot.coords.x) + "%;\n        ")),on:{"click":function($event){$event.stopPropagation();return _vm.changeScene(spot.scene)}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(spot.label))]),_c('img',{staticClass:"vca-map-spot-hover-image",attrs:{"src":spot.hoverImg,"alt":((spot.name) + "-spot-hover-image")}}),_vm._m(0,true)])})],2),_c('div',{staticClass:"vca-map-floor vca-map-second-floor",class:{ visible: _vm.currentFloor === 2 }},[_c('img',{attrs:{"src":_vm.map.secondFloorImg,"alt":"second-floor"}}),_vm._l((_vm.map.secondFloorSpots),function(spot){return _c('button',{key:("first-floor-spot-" + (spot.name)),staticClass:"vca-map-spot",style:(("\n          top: " + (spot.coords.y) + "%;\n          left: " + (spot.coords.x) + "%;\n        ")),on:{"click":function($event){$event.stopPropagation();return _vm.changeScene(spot.scene)}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(spot.label))]),_c('img',{staticClass:"vca-map-spot-hover-image",attrs:{"src":spot.hoverImg,"alt":((spot.name) + "-spot-hover-image")}}),_vm._m(1,true)])})],2),_c('div',{staticClass:"map-floor-selector"},_vm._l((_vm.map.floorNames),function(floor,idx){return _c('button',{key:("map-floor-" + (idx + 1)),staticClass:"map-floor-btn",class:{
          'current-floor': (idx + 1) === _vm.currentFloor
        },on:{"click":function($event){$event.stopPropagation();return _vm.setFloor(idx+1)}}},[_vm._v(" "+_vm._s(floor)+" ")])}),0)]):_c('div',{staticClass:"vca-map-mobile"},[_c('img',{staticClass:"vca-map-logo",attrs:{"src":_vm.map.vcaLogoImg,"alt":"vca-logo"}}),_c('div',{staticClass:"map-floor-selector"},_vm._l((_vm.map.floorNames),function(floor,idx){return _c('button',{key:("map-floor-" + (idx + 1)),staticClass:"map-floor-btn",class:{
          'current-floor': (idx + 1) === _vm.currentFloor
        },on:{"click":function($event){$event.stopPropagation();return _vm.setFloor(idx+1)}}},[_vm._v(" "+_vm._s(floor)+" ")])}),0),_c('div',{staticClass:"map-spots-wrapper"},[_c('div',{staticClass:"map-spots-floor-wrapper",class:{
          visible: _vm.currentFloor === 1,
        }},_vm._l((_vm.map.firstFloorSpots),function(spot){return _c('button',{key:("first-floor-" + (spot.name) + "-spot"),staticClass:"vca-map-spot",style:(("\n            background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + (spot.hoverImg) + ");\n          ")),on:{"click":function($event){$event.stopPropagation();return _vm.changeScene(spot.scene)}}},[_c('div',{staticClass:"spot-label"},[_vm._v(" "+_vm._s(spot.label)+" ")]),_c('div',{staticClass:"chevron-wrap"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512","fill":"#fff"}},[_c('path',{attrs:{"d":"M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"}})])])])}),0),_c('div',{staticClass:"map-spots-floor-wrapper",class:{
          visible: _vm.currentFloor === 2,
        }},_vm._l((_vm.map.secondFloorSpots),function(spot){return _c('button',{key:("first-floor-" + (spot.name) + "-spot"),staticClass:"vca-map-spot",style:(("\n            background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + (spot.hoverImg) + ");\n          ")),on:{"click":function($event){$event.stopPropagation();return _vm.changeScene(spot.scene)}}},[_c('div',{staticClass:"spot-label"},[_vm._v(" "+_vm._s(spot.label)+" ")]),_c('div',{staticClass:"chevron-wrap"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512","fill":"#fff"}},[_c('path',{attrs:{"d":"M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"}})])])])}),0)])]),_c('button',{staticClass:"close-button",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v("×")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spot"},[_c('div',{staticClass:"spot-inner"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spot"},[_c('div',{staticClass:"spot-inner"})])}]

export { render, staticRenderFns }