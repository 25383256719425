export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `하이 주얼리 아틀리에`,
    content: [
      `메종은 백 년 이상 스톤에 대한 사랑과 독자적인 노하우, 기술 성과를 결집해왔습니다.`,
      `하이 주얼리 작품이 완성되는 역사적인 아틀리에는 파리 방돔 광장에 있습니다.`,
      `아틀리에에서 보석 세공사와 스톤 세터, 주얼러가 최상의 스톤과 소재로 작업합니다.`,
      `이번 방문으로 한 작품을 완성하는 데 필요한 엄격함과 섬세함 그리고 탁월함을 발견하세요.`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `글로벌 마케팅팀`, // titre de la dialog
    content: [
      `글로벌 마케팅팀은 컬렉션 개발을 위해 다양한 부서(스톤, 스튜디오 등)와 협력합니다.`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `하이 주얼리 아틀리에`,
    content: [
      `메종의 작품은 아틀리에에서 완성한 디자인을 기초로 제작됩니다.`,
      `하이 주얼리 작품은 공동의 결과물로, 이 작품 하나에 다양한 분야의 전문 노하우가 녹아들어 있습니다.`,
    ],
  },
  {
    name: `creation_studio`,
    title: `창작 스튜디오`,
    content: [
      `디자인은 창작 과정의 첫 번째 단계입니다. 스튜디오에서 디자이너들은 새로운 컬렉션을 구상하고 이를 형태와 컬러로 옮깁니다.`,
      `기법을 확인받고 니콜라 보스 회장의 승인을 받으면 디자이너가 이를 구아슈로 구현하면서 볼륨과 컬러, 빛의 유희를 사실적으로 표현합니다.`,
      `기술적으로 구현이 가능한지 알아보기 위해 모형을 제작하기도 합니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/dessinatrice/dessinatrice.en_US.mp4`),
  },
  {
    name: `stones_expert`,
    title: `스톤 전문가`,
    content: [
      `보석 감정사는 프레셔스 스톤과 장식석을 확인하고 선별하는 전문가입니다.`,
      `메종의 까다로운 기준에 부합하는 스톤을 찾는 것이 주요 업무입니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.ko_KR.mp4`),
  },
  {
    name: `tin_models`,
    title: `주석 모형`,
    content: [
      `모형 제작가는 창작물의 볼륨을 검토하기 위해 모형을 만듭니다. `,
      `디자인을 보고 주석과 스트라스로 모형을 제작해 구아슈를 3차원으로 재해석합니다. `,
      `이 중요한 단계를 거쳐야 볼륨을 시각적으로 확인하고 기술 방법을 예상하며 스톤의 중량을 가늠할 수 있습니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.ko_KR.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `폴리셔의 노하우`,
    content: [
      `폴리셔는 폴리싱을 통해 작품을 빛나게 만드는 전문가입니다.`,
      `폴리싱의 목적은 작품의 안과 밖에 모두 광채를 내는 것입니다.`,
      `실, 브러시, 연마제와 같은 도구를 이용하는 폴리셔는 세팅 전과 후, 최종 조립 이후 등 여러 제작 단계에 걸쳐 폴리싱 작업을 합니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.ko_KR.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `폴리셔의 도구`,
    content: [
      `모든 작품은 세팅 전에 전체적으로 폴리싱합니다.`,
      `작품 내부는 면직물과 연마제를 이용해 폴리싱합니다.`,
      `모든 폴리싱 작업에는 각 작품에 맞는 다양한 크기와 형태의 도구가 필요합니다.`,
    ],
  },
  {
    name: `quality_controller`,
    title: `품질 관리자 `,
    content: [
      `품질 관리자는 제품이 부티크에 입고되기 전에 제품의 적합성과 품질을 확인하는 업무를 담당합니다. `,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `작업대`,
    content: [
      `태장대: 모든 장인은 작업 방식에 따라 다양하게 사용할 수 있는 태장대가 있습니다. 장인들은 자리를 바꿀 수 있지만 각자의 태장대는 장인들이 이동할 때 함께 가지고 이동합니다.`,
      `피혁: 작업대 아래에 깔면 작은 부품이나 스톤, 조각난 재료를 회수할 수 있습니다.`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `주얼러의 노하우`,
    content: [
      `주얼러는 귀금속을 사용해 구아슈와 모형을 기초로 입체적인 구조를 구현합니다. `,
      `Van Cleef & Arpels 장인들의 탁월한 노하우는 디테일과 편안한 착용감에 대한 섬세한 배려, 메커니즘의 독창성, 스톤 사이로 빛을 통과시키는 기교에서 나타납니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.ko_KR.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `스톤 세터의 노하우`,
    content: [
      `스톤 세터의 역할은 프레셔스 스톤이나 장식석을 귀금속 구조물에 삽입하면서 스톤이 주얼리에서 더욱 빛날 수 있도록 합니다.`,
      `그러기 위해서는 마운트를 가능한 한 보이지 않게 감추면서 스톤을 세팅해야 합니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.ko_KR.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `감탕대`,
    content: [
      `스톤 세터는 안정적으로 보석을 세팅하기 위해 감탕대에 금속 뼈대를 일시적으로 고정합니다.`,
    ],
  },
  {
    name: `tools_variety`,
    title: `다양한 도구`,
    content: [
      `스톤 세터는 모든 형태와 크기의 스톤을 다룹니다. 따라서 조각정, 비딩 툴, 감탕대 등 필요에 따른 매우 다양한 도구 세트를 가지고 있습니다.`,
      `스톤 세터는 작업하는 작품에 적합하게 사용하기 위해 자신만의 도구를 만들게 됩니다.`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `제련사의 노하우`,
    content: [
      `주얼러가 녹색 밀랍으로 모형을 완성해 이를 제련사에게 전달하면 제련사는 골드 소재의 최종 형태를 만들게 됩니다.`,
      `‘로스트 왁스 주조’ 방식은 석고 주형을 만든 다음 가마에 넣어 밀랍을 녹이고 그 안에 금속을 부어 넣는 기법입니다.`,
      `이 과정에는 고도로 숙련된 능력과 정확도가 있어야 창작물의 품질을 보장할 수 있습니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.ko_KR.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `다이아몬드 세공사의 노하우`,
    content: [
      `스톤 커팅, 특히 다이아몬드 커팅 전문가인 다이아몬드 세공사는 원석에서 광채를 구현합니다.`,
      `가장 단단한 스톤인 다이아몬드 가공에는 노하우와 기술 그리고 특별한 전문성이 있어야 강렬한 광채와 섬광을 이끌어 낼 수 있습니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.ko_KR.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `미스터리 세팅`,
    content: [
      `메종이 1933년 특허를 받은 위대한 업적인 미스터리 세팅에는 경험과 섬세함 그리고 끈기가 필요합니다. `,
      `스톤을 하나하나 다듬고 얇은 골드 레일에 끼워 넣어서 금속 구조물을 완벽하게 가립니다.`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `보석 세공사의 노하우`,
    content: [
      `보석 세공사는 보석과 장식석을 다듬고 연마하는 전문가입니다.`,
      `보석 세공사의 노하우는 특히 미스터리 세팅을 구현할 때 발현됩니다.`,
      `소수의 장인만이 이 노하우를 가지고 있습니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.ko_KR.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `CAD 디자이너의 노하우`,
    content: [
      `CAD(Computer Aided Design) 디자이너는 일부 매우 복잡한 작품의 경우 주얼러와 긴밀히 협력하여 구아슈를 기초로 작품을 3차원으로 디지털화합니다.`,
      `유서 깊은 노하우에 현대 기술을 접목하는 것입니다.`,
      `아틀리에에서 작품을 구현하기 전에 이 단계를 통해 작품의 볼륨을 연구하고 그로부터 다양한 메커니즘을 구상하면서 기술적 어려움을 예상합니다.`,
    ],
    videoCTA: `비즈니스 비디오 보기`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.ko_KR.mp4`),
  },
];
