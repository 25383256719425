export default [
  {
    name: `splash_screen`,
    startCTA: `НАЧАТЬ`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `Экскурсия 360° по нашим мастерским Высокого ювелирного искусства`,
      },
      {
        name: `movement`,
        text: `Перемещайтесь с помощью стрелок на полу`,
      },
      {
        name: `hotspots`,
        text: `Нажимайте на кружки, чтобы узнать больше`,
      },
    ],
    gameTutorial: `На всем протяжении экскурсии собирайте драгоценные камни`,
    // browserTip: `Для этой экскурсии лучше всего использовать браузер Google Chrome.`,
    startCTA: `ПРОДОЛЖИТЬ`,
  }
];
