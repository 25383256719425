export default {
  name: `tutorial`,
  title: `チュートリアル`,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `画像を360度見るには、マウスをクリックしグライドしてください`
    },
    {
      name: `arrows_movement`,
      text: `地面の矢印を使って移動してください`,
    },
    {
      name: `hotspots`,
      text: `さらに詳しくは、それぞれの円をクリックしてください`,
    },
  ],
  continueCTA: `スキップする`,
}