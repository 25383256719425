export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `خريطة ورش العمل`,
    },
    {
      name: `toggle_fullscreen`,
      text: `وضع ملء الشاشة`,
    },
  ]
}