<template>
  <div
    class="spaces-scene-wrapper"
    :class="{
      visible: true,
      mobile: $breakpoints.isMobile,
      inactive: dialogOpen,
      dragging: isDragging,
    }"
    @click.prevent="closeDialog"
  >
    <div id="spaces-scene-first-wrapper" class="spaces-scene-container-wrapper" :class="{visible: currentSceneVisible === 0}">
      <canvas id="spaces-scene-first" class="scene-container"></canvas>
      <template v-for="(spot, idx) in spots2DScene0">
        <vca-hotspot
          :spot-config="spot"
          :idx="`${idx + 1}`"
          :key="`${scene.name}-${spot.name}`"
          @click="handleSpotClick"
          v-if="spot.type === `basic-spot`"
        />
        <game-hotspot
          :spot-config="spot"
          :game-config="gameConfig"
          :game-status="gameStatus"
          :key="`${scene.name}-${spot.name}`"
          @click="gemFound"
          v-else-if="spot.type === `game-spot`"
        />
      </template>
      <label-hotspot
        :spot-config="spot"
        :visited="visitedScenes[spot.action.args.scene] === true"
        :key="`${scene.name}-${spot.name}`"
        v-for="spot in spots3DScene0"
      />
    </div>
    <div id="spaces-scene-second-wrapper" class="spaces-scene-container-wrapper" :class="{visible: currentSceneVisible === 1}">
      <canvas id="spaces-scene-second" class="scene-container"></canvas>
      <template v-for="(spot, idx) in spots2DScene1">
        <vca-hotspot
          :spot-config="spot"
          :idx="`${idx + 1}`"
          :key="`${scene.name}-${spot.name}`"
          @click="handleSpotClick"
          v-if="spot.type === `basic-spot`"
        />
        <game-hotspot
          :spot-config="spot"
          :game-config="gameConfig"
          :game-status="gameStatus"
          :key="`${scene.name}-${spot.name}`"
          @click="gemFound"
          v-else-if="spot.type === `game-spot`"
        />
      </template>
      <label-hotspot
        :spot-config="spot"
        :visited="visitedScenes[spot.action.args.scene] === true"
        :key="`${scene.name}-${spot.name}`"
        v-for="spot in spots3DScene1"
      />
    </div>
  </div>
</template>

<script>
import Spaces from '@/Spaces';
import VcaHotspot from '@/components/VcaHotspot';
import GameHotspot from '@/components/GameHotspot';
import LabelHotspot from '@/components/LabelHotspot';

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
  return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

let spacesApp;
const spacesApps = [];

export default {
  components: {
    VcaHotspot,
    GameHotspot,
    LabelHotspot,
  },

  props: {
    scene: {
      type: Object,
      required: true,
    },
    scenesConfig: {
      type: Array,
      required: true,
    },
    gameConfig: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    dialogOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    visitedScenes: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    isAnimateEnabled: false,
    isGoingToScene: false,
    isMouseDown: false,
    isDragging: false,
    isUserInteracting: false,
    currentSceneName: null,
    previousSceneName: null,
    currentSceneI: 1,
    currentSceneVisible: 1,
    canCloseDialog: false,
    spots2D: [],
    spots3D: [],
  }),

  computed: {
    sceneNames() {
      const sceneNames = {};

      for (const s of this.scenesConfig) {
        if (s.name === `global` || typeof s.label === `undefined`) {
          continue;
        }
        sceneNames[s.name] = s.label;
      }
      return sceneNames;
    },
    hubHotspots() {
      return this.scene.hubHotspots;
    },
    sceneHotspots() {
      return this.scene.sceneHotspots;
    },
    firstSceneContainer() {
      return document.getElementById(`spaces-scene-first`);
    },
    secondSceneContainer() {
      return document.getElementById(`spaces-scene-second`);
    },
    spots2DScene0() {
      return this.spots2D.filter(spot => spot.$sceneIndex === 0);
    },
    spots2DScene1() {
      return this.spots2D.filter(spot => spot.$sceneIndex === 1);
    },
    spots3DScene0() {
      return this.spots3D.filter(spot => spot.$sceneIndex === 0 && typeof spot.label !== `undefined`);
    },
    spots3DScene1() {
      return this.spots3D.filter(spot => spot.$sceneIndex === 1 && typeof spot.label !== `undefined`);
    },
    globalScenesConfig() {
      return this.scenesConfig.find(scene => scene.name === `global`) || {};
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    closeDialog() {
      if (this.dialogOpen && this.canCloseDialog) {
        this.$emit(`close-dialog`);
      }
    },
    startTicking(idx = -1) {
      if (idx < 0) {
        spacesApp.time.startTicking();
      } else {
        spacesApps[idx].time.startTicking();
      }
    },
    stopTicking(idx = -1) {
      if (idx < 0) {
        spacesApp.time.stopTicking();
      } else {
        spacesApps[idx].time.stopTicking();
      }
    },
    mainSphereZoneClicked(action, target) {
      // this.playSoundEffect();

      switch (action) {
        case `clickProduct`:
          this.$emit(`open-product`, target);
          break;
        case `clickArrow`:
          this.$emit(`change-scene`, target);
          break;
        default:
          break;
      }
    },
    setSceneSpots(spotsHandler = {}) {
      if (this.scene.hotspots) {
        const spotsEventListeners = {
          events2D: [],
          events3D: [],
        };

        if (this.scene.hotspots.spots2D) {
          spotsEventListeners.events2D.push({
            type: `spot-2d-position`,
            cb: this.setSpotViewportConfig,
          });
          spotsEventListeners.events2D.push({
            type: `spot-3d-position`,
            cb: this.update3DSpotsNamesPosition,
          });
        }

        if (this.scene.hotspots.spots3D) {
          const spots3DActions = [];

          this.scene.hotspots.spots3D.forEach((spot) => { if (!spots3DActions.includes(spot.action.type)) spots3DActions.push(spot.action.type) });
          spotsEventListeners.events3D = [
            ...spots3DActions.map((action) => ({ type: action, cb: this.handleSpotClick })),
          ];
        }

        const spots = {
          spots2D: this.spots2D.filter(spot => spot.$sceneIndex === this.currentSceneI),
          spots3D: this.spots3D.filter(spot => spot.$sceneIndex === this.currentSceneI)
        }

        spacesApps[this.currentSceneI].setSpots(spots, spotsHandler, spotsEventListeners);
      }
    },
    async setCurrentScene() {
      const sphereAssetsHandler = {};
      const sphereAssets = [
        { name: this.scene.name, type: `tex`, url: this.scene.tex },
        { name: this.scene.name, type: `matBas`, map: this.scene.name },
      ];

      await spacesApps[this.currentSceneI].addAssets(sphereAssets, sphereAssetsHandler, `sphere`);
      spacesApps[this.currentSceneI].setSphere(sphereAssetsHandler, { name: this.scene.name, initialCamera: this.scene.initialCamera });

      this.setSceneSpots();
    },
    async createCurrentScene() {
      console.log(`[${new Date().toISOString()}][createCurrentScene] Started 'createCurrentScene' function`);
      const containerToMount = this.currentSceneI === 0 ? this.firstSceneContainer : this.secondSceneContainer;

      const tmpSpacesApp = new Spaces(containerToMount);
      const sphereHandler = {};
      const spotsHandler = {};
      const assetsSphere = [
        // { name: `sphere`, type: `glb`, url: require(`@/assets/glbs/sphere.glb`) },
        { name: `sphere`, type: `glb`, url: this.globalScenesConfig.sphere },
        { name: this.scene.name, type: `tex`, url: this.scene.tex },
        { name: this.scene.name, type: `matBas`, map: this.scene.name },
      ];

			const assetsSpots = [
        // Texs
				{ name: `arrow_00`, type: `tex`, url: this.globalScenesConfig.arrow0 },
				{ name: `arrow_01`, type: `tex`, url: this.globalScenesConfig.arrow1 },
				{ name: `arrow_02`, type: `tex`, url: this.globalScenesConfig.arrow2 },
				{ name: `arrow_03`, type: `tex`, url: this.globalScenesConfig.arrow3 },
				{ name: `arrow_0H`, type: `tex`, url: this.globalScenesConfig.arrowHighlight },
        // Mats
        { name: `arrow_00`, type: `matBas`, map: `arrow_00`, transparent: true },
        { name: `arrow_01`, type: `matBas`, map: `arrow_01`, transparent: true },
        { name: `arrow_02`, type: `matBas`, map: `arrow_02`, transparent: true },
        { name: `arrow_03`, type: `matBas`, map: `arrow_03`, transparent: true },
        { name: `arrow_0H`, type: `matBas`, map: `arrow_0H`, transparent: true },
      ];

      console.log(`[${new Date().toISOString()}][createCurrentScene] Started adding sphere assets`);
      await tmpSpacesApp.addAssets(assetsSphere, sphereHandler, `sphere`);
      console.log(`[${new Date().toISOString()}][createCurrentScene] Added sphere assets`);
      tmpSpacesApp.setSphere(sphereHandler);

      console.log(`[${new Date().toISOString()}][createCurrentScene] Started adding spots assets`);
			await tmpSpacesApp.addAssets(assetsSpots, spotsHandler, `spots`);
      console.log(`[${new Date().toISOString()}][createCurrentScene] Added spots assets`);

      tmpSpacesApp.ray.on(`clickProduct`, (product) => { this.mainSphereZoneClicked(`clickProduct`, product) });
      tmpSpacesApp.ray.on(`clickArrow`, (scene) => { this.mainSphereZoneClicked(`clickArrow`, scene) });
      tmpSpacesApp.pointer.on(`start-dragging`, () => { this.isDragging = true });
      tmpSpacesApp.pointer.on(`stop-dragging`, () => { this.isDragging = false });

      spacesApps[this.currentSceneI] = tmpSpacesApp;

      this.setSceneSpots(spotsHandler);
      console.log(`[${new Date().toISOString()}][createCurrentScene] Ended 'createCurrentScene' function`);
    },
    removePreviousSpots() {
      this.spots2D = this.spots2D.filter((spot) => spot.$sceneIndex === this.currentSceneI);
      this.spots3D = this.spots3D.filter((spot) => spot.$sceneIndex === this.currentSceneI);
    },
    async changeScene() {
      console.log(`[${new Date().toISOString()}] Changing to scene '${this.scene.name}'`);
      this.currentSceneI = (this.currentSceneI + 1) % 2;

      if (this.scene.hotspots) {
        this.spots2D = [
          ...this.spots2D,
          ...this.scene.hotspots.spots2D.map((spot) => ({ ...spot, $sceneIndex: this.currentSceneI }))
        ];
        this.spots3D = [
          ...this.spots3D,
          ...this.scene.hotspots.spots3D.map((spot) => ({ ...spot, $sceneIndex: this.currentSceneI, label: this.sceneNames[spot.action.args.scene] }))
        ];
      }
      console.log(`[${new Date().toISOString()}] Hotspots set`);

      if (!spacesApps[this.currentSceneI]) {
        console.log(`[${new Date().toISOString()}] Creating scene`);
        await this.createCurrentScene();
        console.log(`[${new Date().toISOString()}] Scene created`);
      } else {
        await this.setCurrentScene();
        console.log(`[${new Date().toISOString()}] Scene set`);
      }

      spacesApp = spacesApps[this.currentSceneI];

      if (this.gyroEnabled && typeof this.gyroDatas.alpha !== `undefined`) {
        spacesApp.gyro.orien.a = this.gyroDatas.alpha;
        spacesApp.gyro.orien.b = this.gyroDatas.beta;
        spacesApp.gyro.orien.g = this.gyroDatas.gamma;
        spacesApp.gyro.enableGyro();
      }
      spacesApp.sphere.setLocation(this.scene.name, this.scene.initialCamera);

      this.startTicking();
      console.log(`[${new Date().toISOString()}] Scene started ticking`);
      await this.startSceneTransition();
      console.log(`[${new Date().toISOString()}] Scene started transition`);

      this.$emit(`scene-mounted`);
      console.log(`[${new Date().toISOString()}] Emitted 'scene-mounted'`);
    },
    disposePreviousScene() {
      let previousSceneI = (this.currentSceneI + 1) % 2;

      if (spacesApps.length === 1)
        return;

      spacesApps[previousSceneI].disposeScene(this.previousSceneName);
    },
    async startSceneTransition() {
        const toFadeIn = this.currentSceneI === 0 ? this.firstSceneContainer : this.secondSceneContainer;
        const toFadeOut = this.currentSceneI === 1 ? this.firstSceneContainer : this.secondSceneContainer;

        toFadeIn.style.transition = `opacity 0.5s ease-in-out`;
        if (toFadeOut)
          toFadeOut.style.transition = `opacity 0.8s ease-in-out`;
        this.currentSceneVisible = this.currentSceneI;

        // wait for fade to end to dispose previous scene
        await this.wait(500);

        this.disposePreviousScene();
        this.removePreviousSpots();
    },
    openVideo(videoName) {
      this.$emit(`open-video`, videoName);
    },
    update3DSpotsNamesPosition(name, sceneIndex, visible, coords) {
      const indexToUpdate = this.spots3D.findIndex(spot => spot.name === name && spot.$sceneIndex === sceneIndex);
      const updated = {
        ...this.spots3D[indexToUpdate],
        visible,
        coords
      };
      this.$set(this.spots3D, indexToUpdate, updated);
    },
    setSpotViewportConfig(name, sceneIndex, visible, coords) {
      const indexToUpdate = this.spots2D.findIndex(spot => spot.name === name && spot.$sceneIndex === sceneIndex);
      const updated = {
        ...this.spots2D[indexToUpdate],
        visible,
        coords
      };

      this.$set(this.spots2D, indexToUpdate, updated);
    },
    changeSceneFromParent(scene) {
      this.stopTicking();
      this.$emit(`change-scene`, scene);
    },
    gemFound(color) {
      this.$emit(`gem-found`, color);
    },
    handleSpotClick(action) {
      switch (action.type) {
        case `change-scene`:
          this.stopTicking();
          this.$emit(`change-scene`, action.args.scene);
          break;
        case `dialog`:
          this.$emit(`open-dialog`, action.args.dialog);
          break;
        case `found-gem`:
          this.$emit(`found-gem`, action.args.color);
          break;
        default:
          console.log(action.type);
      }
    }
  },

  async mounted() {
    this.currentSceneName = this.scene.name;
    this.changeScene();
  },

  watch: {
    async scene(newScene, prevScene) {
      this.previousSceneName = prevScene.name;
      this.currentSceneName = newScene.name;
      this.changeScene();
    },
    dialogOpen(isOpened) {
      if (isOpened) {
        this.canCloseDialog = false;
        setTimeout(() => {
          this.canCloseDialog = true;
        }, 100);
      }
    },
		gyroEnabled(gyroEnabled) {
			if (gyroEnabled) {
				spacesApp.gyro.enableGyro();
      } else {
				spacesApp.gyro.disableGyro();
      }
    },
    gyroDatas(gyroDatas) {
      if (this.gyroEnabled && typeof spacesApp !== `undefined`) {
        spacesApp.gyro.orien.a = gyroDatas.alpha;
        spacesApp.gyro.orien.b = gyroDatas.beta;
        spacesApp.gyro.orien.g = gyroDatas.gamma;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$genericHotspotSizeMobile: 25px;
$genericHotspotSizeDesktop: 35px;
$imgHotspotSize: 40px;

.spaces-scene-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: grab;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &.dragging {
    .hotspot {
      pointer-events: none !important;
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    img {
      width: 100%;
    }
  }

  .spaces-scene-container-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .scene-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

  }


  .hotspots-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    * {
      pointer-events: all;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    z-index: 95;
  }

  &.dragging {
    .generic-hotspot,
    .dialog-hotspot,
    .scene-hotspot,
    .hub-hotspot {
      pointer-events: none;
    }
  }

  .generic-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    height: $genericHotspotSizeMobile;
    width: $genericHotspotSizeMobile;
    border-radius: 50px;
    white-space: nowrap;
    border: 3px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &.hub {
      height: $genericHotspotSizeDesktop * 1.1;
      width: $genericHotspotSizeDesktop * 1.1;

      .legend {
        font-weight: 300;
      }
    }
  }

  .hub-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    font-size: 11px;
    border-radius: 50px;
    white-space: nowrap;
    border: 5px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 6px 11px;
  }

  .dialog-hotspot,
  .scene-hotspot {
    position: absolute;
    width: $imgHotspotSize;
    height: $imgHotspotSize;
    top: -100px;
    left: -100px;
    margin-top: -($imgHotspotSize / 2);
    margin-left: -($imgHotspotSize / 2);
    cursor: pointer;
    z-index: 95;
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot {
    cursor: pointer;

    .img-icon {
      height: $genericHotspotSizeMobile - 11px;
    }

    .hotspot-legend {
      position: absolute;
      top: $imgHotspotSize + 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFF;
      font-size: 13px;
      border-radius: 3px;
      white-space: nowrap;
      border: 8px solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      padding: 6px;
      color: #2c3e50;
      display: none;
      pointer-events: none;
    }

    &:hover {
      .hotspot-legend {
        display: block;
      }
    }
  }

  .scene-hotspot {
    cursor: default;

    img {
      cursor: pointer;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .legend {
      pointer-events: none;
      cursor: default;
    }
  }

  &:not(.mobile) {
    .generic-hotspot {
      height: $genericHotspotSizeDesktop;
      width: $genericHotspotSizeDesktop;

      &:hover {
        border: 2px solid #FFF;
      }

      &.hub.has-legend {
        height: $genericHotspotSizeDesktop * 1.2;
        width: $genericHotspotSizeDesktop * 1.2;
        &:hover {
          width: auto;
          height: $genericHotspotSizeDesktop * 1.4;
          z-index: 100;

          .img-icon-wrapper {
            display: none;
          }
        }
        &:not(:hover) {
          .legend {
            display: none;
          }
        }
      }
    }
  }

  .scene-hotspot {
    .hotspot-legend {
      display: block;
    }
  }

  .generic-hotspot.exit-button {
    bottom: 30px;
    left: 50%;
    top: auto;
    right: auto;
    font-size: 28px;
    height: $genericHotspotSizeMobile * 2;
    width: $genericHotspotSizeMobile * 2;
  }


  &.inactive {

    .spaces-scene-container-wrapper {
      pointer-events: none;
    }
  }
}

.dialog-hotspot img,
.scene-hotspot img {
  width: 100%;
  height: 100%;
}
</style>
