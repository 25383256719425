export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `THE HIGH JEWELRY WORKSHOPS`,
    content: [
      `For more than a century, Van Cleef & Arpels has been uniting its passion for gemstones with unique expertise and technical achievements.`,
      `The historical workshops, dedicated to High Jewelry designs, are located on Place Vendôme in Paris.`,
      `Lapidaries, gem-setters and jewelers craft the most precious stones and metals within these workshops.`,
      `Come discover the meticulousness, exactingness, and excellence required to produce each piece.`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `THE INTERNATIONAL MARKETING TEAMS`, // titre de la dialog
    content: [
      `The International Marketing team works hand-in-hand with the various departments (gemstones, studio, etc.) to create a collection.`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `THE HIGH JEWELRY WORKSHOP`,
    content: [
      `Our designs are crafted at our workshops based on drawings. `,
      `A piece of High Jewelry is a collective undertaking, and we will see which professions are involved in its creation.`,
    ],
  },
  {
    name: `creation_studio`,
    title: `THE CREATION STUDIO`,
    content: [
      `The drawing is the first step in the creative process. As the designers of the Studio compose new creations, they consider their shapes and colors. `,
      `After the technical confirmation and approval of Nicolas Bos, each design is depicted in a gouache painting, which more accurately represents its shapes, colors and light effects.`,
      `Sometimes a mock-up is necessary to analyze the technical feasibility of the piece.`,
    ],
        videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/dessinatrice/dessinatrice.en_US.mp4`),
  },
  {
    name: `stones_expert`,
    title: `THE GEMSTONE EXPERT`,
    content: [
      `The gemologist is an expert in the identification and selection of precious or ornamental stones.`,
      `His or her mission is to find the gems that correspond with the Maison’s exacting criteria.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.en_US.mp4`),
  },
  {
    name: `tin_models`,
    title: `THE PEWTER MOCK-UP`,
    content: [
      `The model-maker creates mock-ups of the designs in order to better examine their shapes.`,
      `Based on the design shown in the gouache painting, the three-dimensional mock-up is crafted in pewter and rhinestones.`,
      `This essential step makes it possible to visualize the shape of the design, to anticipate technical solutions, and to make an initial estimation of the stones’ weight.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.en_US.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `THE PROFESSION OF POLISHER`,
    content: [
      `The polisher brings out the full shine of the jewelry designs through polishing steps.`,
      `The objective of polishing is to shine all the interior and exterior surfaces of the piece. `,
      `Using tools – threads, brushes and abrasive pastes –, the polisher intervenes at various stages in the crafting process: before gem-setting, after gem-setting, and after the final assembly.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.en_US.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `THE POLISHER’S TOOLS`,
    content: [
      `Each piece is polished on every side before being gem-set.`,
      `The interior of the piece is polished – or “thrummed” – using thread and polishing paste.`,
      `Each polishing task requires tools of varying sizes and shapes to adapt to each piece.`,
    ],
  },
  {
    name: `quality_controller`,
    title: `QUALITY CONTROLLER`,
    content: [
      `The quality controller’s role is to ensure the quality and conformity of all pieces before they are made available for sale. `,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `THE WORKBENCH`,
    content: [
      `The workbench peg: Each craftsman has a peg on their workbench that is used in different ways depending on their approach to the work. The craftsmen may change work stations as they perform their tasks, but they take their peg with them when they do.`,
      `Bench skin: stretched beneath the surface of the bench, the bench skin catches small pieces, stones, and precious materials – even in dust-fine particles – so that they may be recuperated.`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `THE PROFESSION OF JEWELER`,
    content: [
      `Working in precious metal, the jeweler crafts the three-dimensional structure of the piece based on the gouache painting and 3D model.`,
      ` The exacting savoir-faire of the Van Cleef & Arpels craftsmen is reflected in the care that they bring to the details of the pieces, their ingenious mechanisms, their comfort in wear, and the finesse of the openworking that allows light to shine through the stones.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.en_US.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `THE PROFESSION OF GEM-SETTER`,
    content: [
      `The role of the gem-setter is to fit precious or ornamental stones into the precious metal framework and bring out their beauty in the piece of jewelry. `,
      `The gemstones must be firmly held in place while the metal disappears as much as possible.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.en_US.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `THE JEWEL-SETTING STICK`,
    content: [
      `The gem-setter uses a jewel-setting stick to temporarily secure the metal framework to ensure a better fastening when definitively setting the gems.`,
    ],
  },
  {
    name: `tools_variety`,
    title: `TOOL VARIETY`,
    content: [
      `The gem-setter works on stones of every shape and size. He or she therefore has a range of very diverse tools to meet every need: burins, beaders, jewel-setting sticks, and so on. `,
      `Sometimes the gem-setter creates new tools that are adapted to the piece being crafted.`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `THE PROFESSION OF CASTER`,
    content: [
      `When the jeweler finishes the mock-up sculpture of a piece in green wax, he or she passes it on to the caster for use in pouring the final piece in gold. `,
      `The “lost wax casting” technique consists of creating a plaster mold of the design and then firing it in a kiln to melt the wax before pouring the molten metal into the plaster. `,
      `This process requires great mastery and extreme precision to ensure the quality of the piece.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.en_US.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `THE PROFESSION OF DIAMOND-CUTTER`,
    content: [
      `An expert in cutting gemstones – specifically the diamond –, the diamond-cutter reveals an intense sparkle from the rough stone.`,
      `The diamond is an exceptionally hard stone, so it requires specific savoir-faire, techniques and expertise to reveal its full dazzle, scintillation and light.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.en_US.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `THE MYSTERY SET`,
    content: [
      `A true achievement patented in 1933 by Van Cleef & Arpels, this gem-setting technique requires experience, meticulousness and a great deal of patience. `,
      `The gems are cut one by one and then aligned along fine rails so that the metal structure completely disappears.`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `THE PROFESSION OF LAPIDARY`,
    content: [
      `The lapidary cuts and polishes precious and ornamental stones.`,
      `The lapidary’s savoir-faire is most clearly demonstrated in the crafting of Mystery Set pieces.`,
      `Only a handful of craftsmen master this expertise.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.en_US.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `THE PROFESSION OF CAD MODEL-MAKER`,
    content: [
      `For certain pieces, and particularly the most complex pieces, the CAD (Computer-Aided Design) model-maker works in close collaboration with the jeweler to create a three-dimensional digital representation of the design based on the gouache painting. `,
      `This profession bridges the gap between traditional expertise and modern technology.`,
      `This step makes it possible to examine the volume of each component, to consider the various mechanisms, and to anticipate any technical challenges before the piece is crafted at the workshop.`,
    ],
    videoCTA: `WATCH THE PROFESSION VIDEO`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.en_US.mp4`),
  },
];
