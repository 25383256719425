export default {
  name: `tutorial`,
  tutorialElements: [
    {
      name: `360_movement`,
      gif: require(`@/assets/tutorial/mobile/360_movement.gif`),
    },
    {
      name: `arrows_movement`,
      gif: require(`@/assets/tutorial/mobile/arrows_movement.gif`),
    },
    {
      name: `hotspots`,
      gif: require(`@/assets/tutorial/mobile/hotspots.gif`),
    },
  ],
}