export default [
  /* DIALOG PLACE VENDOME */
  {
    name: `jewellery_workshop`,
    title: `ورش عمل المجوهرات الراقية`,
    content: [
      `منذ أكثر من قرن، تجمع الدار بين حب الأحجار الكريمة والمهارات الفريدة والبراعة التقنية`,
      `تقع ورش العمل التاريخية، المخصصة لصياغة إبداعات المجوهرات الراقية، في ساحة فاندوم في باريس.`,
      `داخل ورش العمل يقوم خبراء الأحجار الكريمة والصائغون والمرصّعون بالعمل مع أثمن الأحجار والمعادن.`,
      `تفضلوا بمرافقتنا خلال هذه الزيارة لاكتشاف معايير الدقة والبراعة والتميز الضرورية لصياغة كل إبداع.`,
    ],
  },
  /* DIALOGS VITRINE */
  {
    name: `marketing_teams`,
    title: `فرق التسويق الدولي`, // titre de la dialog
    content: [
      `يعمل فريق التسويق الدولي جنبًا إلى جنب مع الأقسام المختلفة (الأحجار، الاستوديو) لتصميم المجموعة.`, // contenu de la dialog, une entrée par paragraphe, cf dialog place vendôme
    ],
  },
  {
    name: `high_jewellery`,
    title: `ورشة المجوهرات الراقية`,
    content: [
      `يتم صياغة ابتكاراتنا بناءً على رسومات في ورش العمل لدينا.`,
      `كل إبداع من المجوهرات الراقية هو ثمرة عمل جماعي وسنرى ما هي الحرف المختلفة التي تشارك في هذه العملية الإبداعية.`,
    ],
  },
  {
    name: `creation_studio`,
    title: `الاستوديو الإبداعي`,
    content: [
      `الرسم هو الخطوة الأولى في العملية الإبداعية. في الاستوديو يتخيل المصممون إبتكارات جديدة ويصوّرون أشكالها وألوانها.`,
      `بعد التأكيد الفني وموافقة الرئيس التنفيذي نيكولا بوس، يتم تحويل الرسم إلى غواش لتصوير الأحجام والألوان والتأثيرات الضوئية بطريقة أكثر واقعية.`,
      `في بعض الأحيان يكون المجسم من القصدير ضروريًا لدراسة الجدوى الفنية للإبداع.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/dessinatrice/dessinatrice.ar_AR.mp4`),
  },
  {
    name: `stones_expert`,
    title: `خبير الأحجار`,
    content: [
      `خبير الأحجار الكريمة هو خبير في تحديد واختيار الأحجار الكريمة أو الزخرفية.`,
      `وتتمثل مهمته أو مهمتها في العثور على الأحجار الكريمة التي تتوافق مع معايير التميز للدار.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/gemmologue/gemmologue.ar_AR.mp4`),
  },
  {
    name: `tin_models`,
    title: `صانع نماذج القصدير`,
    content: [
      `يصمِّم نماذج الإبداعات من أجل دراسة أحجامها.`,
      `يتمّ صنع نموذج الإبداع من القصدير وأحجار الراين لتجسيد رسم الغواش في ثلاثة أبعاد.`,
      `تتيح هذه الخطوة الأساسية تصوّر الحجم واستباق الحلول التقنية وتقديم تقدير أولي لوزن الأحجار.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/maquettiste/maquettiste.ar_AR.mp4`),
  },
  /* DIALOGS POLISSEUR */
  {
    name: `profession_polisher`,
    title: `مهنة خبير الصقل`,
    content: [
      `يسمح عمل خبير الصقل بالكشف عن تألق الإبتكارات من خلال عمليات الصقل والتلميع المختلفة.`,
      `يهدف الصقل إلى إبراز لمعان كافة الأسطح الداخلية والخارجية للإبداع.`,
      `باستخدام أدواته - الأسلاك والفُرَش والمعاجين الكاشطة - يتدخل خبير الصقل في مراحل مختلفة خلال عملية الصياغة: قبل الترصيع وبعد الترصيع وبعد التجميع النهائي.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/polisseur/polisseur.ar_AR.mp4`),
  },
  {
    name: `polishing_tools`,
    title: `أدوات خبير الصقل`,
    content: [
      `يتم صقل كل قطعة من جميع الجوانب قبل ترصيعها.`,
      `يتم صقل الأجزاء الداخلية للقطع باستخدام خيوط Jaconas وبمساعدة معجون التلميع.`,
      `كل عملية صقل تتطلب أدوات بأحجام وأشكال مختلفة مناسبة للقطعة.`,
    ],
  },
  {
    name: `quality_controller`,
       title: `مراقب الجودة `,
    content: [
      `تتمثل مهمة مراقب الجودة في التأكد من مطابقة القطع وجودتها قبل تسليمها إلى البوتيك.`,
    ],
  },
  /* DIALOG JOAILLER 1 */
  {
    name: `workbench`,
    title: `طاولة العمل`,
    content: [
      `المسند الخشبي: كل حرفي لديه مسند خشبي في مشغله يستخدمه بشكل مختلف حسب طريقة عمله. يمكن للحرفيين تغيير مركز عملهم أثناء العمل لكنهم يحتفظون بمسندهم الخشبي.`,
      `قطعة الجلد: توضع تحت كل طاولة عمل، مما يسمح باستعادة كل من العناصر الصغيرة أو الأحجار، وكذلك المواد المتحوّلة إلى غبار.`,
    ],
  },
  /* DIALOG JOAILLER 2 */
  {
    name: `profession_jeweller`,
    title: `مهنة الصائغ`,
    content: [
      `يعمل الصائغ بالمعدن الثمين، ويصنع هيكل القِطعة ثلاثي الأبعاد انطلاقًا من رسم الغواش ومن نموذج القصدير.`,
      `تنعكس البراعة الفنية المتفوقة التي يتمتع بها حرفيو دار فان كليف أند آربلز في اهتمامهم بأدق التفاصيل وببراعة آليات الإبداعات والراحة في ارتدائها والدقّة في تخريم المعدن لإتاحة مرور الضوء عبر الأحجار.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/joaillier/joaillier.ar_AR.mp4`),
  },
  /* DIALOGS SERTISSEUR */
  {
    name: `profession_crimper`,
    title: `مهنة المرصِّع`,
    content: [
      `يتمثل دور المرصِّع في تثبيت الأحجار الكريمة أو الأحجار الزخرفية في المعدن الثمين وإبرازها على قطع المجوهرات.`,
      `وبالتالي يجب تثبيتها مع جعل الإطار يختفي قدر الإمكان.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/sertisseur/sertisseur.ar_AR.mp4`),
  },
  {
    name: `crimping_stick`,
    title: `عصا الترصيع`,
    content: [
      `يستخدم المرصِّع عصا لتثبيت الإطار المعدني مؤقتًا للحصول على تحكّم أفضل عند الترصيع.`,
    ],
  },
  {
    name: `tools_variety`,
    title: `الأدوات المتنوعة`,
    content: [
      `يعمل المرصِّع على أحجار بجميع الأشكال والأحجام. لذلك، لديه مجموعة من الأدوات المتنوعة لتلبية احتياجاته مثل أداة النقش وأداة تشكيل الزخارف اللؤلؤية وعصا الترصيع، وما إلى ذلك.`,
      `ومن الممكن أن يقوم المرصِّع بإبتكار أداته الخاصة التي تتكيف مع القِطعة التي يعمل عليها.`,
    ],
  },
  /* DIALOG FONDEUR */
  {
    name: `profession_founder`,
    title: `مهنة خبير صياغة المعدن`,
    content: [
      `عندما ينهي الصائغ نحت المجسم بالشمع الأخضر، يعهد بها إلى خبير صياغة المعدن حتى يتمكن من إعطائها شكلها النهائي بالذهب.`,
      `تقضي تقنية "السَّبْك بالشمع الضائع" بصنع قالب من الجبس للقِطعة المراد تنفيذها، ثم يتم وضع القالب في الفرن لإذابة الشمع وصب المعدن فيه.`,
      `تتطلب هذه العملية إتقانًا كبيرًا ودقة فائقة لضمان جودة الإبداعات.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/fondeur/fondeur.ar_AR.mp4`),
  },
  /* DIALOG DIAMANTAIRE */
  {
    name: `profession_diamond_cutter`,
    title: `مهنة خبير قطع الماس`,
    content: [
      `يكشف قطع الماس الذي يملك أيضاً خبرة في قطع الأحجار الثمينة الأخرى عن التألق الشديد للأحجار الخام.`,
      `يتطلب الماس، وهو حجر ذو صلابة استثنائية، معرفة وتقنيات وخبرات محددة من أجل الكشف عن حدّة تألّقه ولمعانه الكاملين.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/diamantaire/diamantaire.ar_AR.mp4`),
  },
  /* DIALOG LAPIDAIRE 1 */
  {
    name: `mysterious_crimp`,
    title: `تقنية الترصيع مستيري سيت`,
    content: [
      `تعدّ هذه التقنية إنجازًا حقيقيًا، وقد سجّلت دار فان كليف أند آربلز براءة اختراعها عام 1933، وهي تتطلّب درجات عالية من الخبرة والدقة والصبر.`,
      `يتم قطع الأحجار الكريمة الواحد تلو الآخر، ثم تنزلق على سكك رفيعة من الذهب من أجل إخفاء الهيكل المعدني تمامًا.`,
    ],
  },
  /* DIALOG LAPIDAIRE 2 */
  {
    name: `profession_lapidary`,
    title: `مهنة خبير ترصيع الأحجار الكريمة`,
    content: [
      `يقوم خبير ترصيع الأحجار الكريمة بنحت وصقل الأحجار النفيسة والزخرفية.`,
      `يُسلّط الضوء على براعة خبير ترصيع الأحجار الكريمة بشكل خاص خلال صياغة الإبداعات المرصعة بتقنية ميستري ست.`,
      `وهناك عدد قليل جداً من خبراء الحرفة الذين يتقنون هذه المهارة.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/lapidaire/lapidaire.ar_AR.mp4`),
  },
  /* DIALOG MODELISTE CAO */
  {
    name: `profession_cao_modeler`,
    title: `مهنة صانع النموذج بواسطة الكمبيوتر CAD`,
    content: [
      `بالنسبة لبعض الإبداعات وتحديداً تلك الأكثر تعقيداً، يتعاون صانع النموذج بواسطة الكمبيوتر بشكل وثيق مع الصائغ لتخيل تمثيل رقمي ثلاثي الأبعاد للإبتكارات استناداً إلى رسم الغواشيه.`,
      `فهذه المهنة تشكّل الرابط بين المهارات التقليدية والتكنولوجيات الحديثة.`,
      `تتيح هذه الخطوة دراسة أحجام الإبداعات وتخيل الآليات المختلفة واستباق التحديات الفنية قبل صياغتها في ورشة العمل.`,
    ],
    videoCTA: `راجع فيديو العمل`,
    video: require(`@/assets/dialogs/videos/profession_cao/profession_cao.ar_AR.mp4`),
  },
];
