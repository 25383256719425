export default {
  name: `game`,
  gemFound: {
    congrats: `ПОЗДРАВЛЯЕМ`,
    foundMessage: `ВЫ ДОБАВИЛИ В КОЛЛЕКЦИЮ НОВЫЙ ДРАГОЦЕННЫЙ КАМЕНЬ!`,
    continueCTA: `ПРОДОЛЖИТЬ`,
  },
  gameWon: {
    congrats: `ОТЛИЧНО!`,
    gemsFound: `ВЫ НАШЛИ ВСЕ КАМНИ!`,
    continueCTA:  `ПРОДОЛЖИТЬ`,
    startOverCTA: `НАЧАТЬ ЭКСКУРСИЮ ЗАНОВО`,
  },
  gemsHover: `ДРАГОЦЕННЫЕ КАМНИ ДЛЯ КОЛЛЕКЦИИ`,
}
