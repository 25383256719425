<template>
  <hotspot-ui
    class="vca-game-hotspot"
    :class="{
      found,
    }"
    :spot-config="spotConfig"
    :action="action"
    v-on="{ [action.type]: execAction }"
  >
    <img :src="gemImage" :alt="`${gemColor}-gem`" />
  </hotspot-ui>
</template>

<script>
import HotspotUi from '@/components/HotspotUi/HotspotUi';

export default {
  components: {
    HotspotUi,
  },

  props: {
    spotConfig: {
      type: Object,
      required: true,
    },
    gameConfig: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Object,
      required: true,
    },
    idx: {
      type: String,
      required: false,
      default: () => ``,
    }
  },

  computed: {
    gemColor() {
      return this.spotConfig.name.split(`_`, )[0];
    },
    found() {
      return this.gameStatus[this.gemColor] === true;
    },
    gemImage() {
      return this.gameConfig.gemsFull[this.gemColor];
    },
    action() {
      return this.spotConfig?.action || { type: ``, args: {} };
    },
    backgroundColor() {
      return this.spotConfig?.backgroundColor || '#ffffff';
    }
  },

  methods: {
    execAction() {
      this.$emit(`click`, this.action.args.color);
    }
  },
}
</script>

<style lang="scss">
.vca-game-hotspot {
  width: 100px;
  height: 100px;

  cursor: pointer;
  button {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    &::before {
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 30px 35px rgb(255, 255, 255, 0.7);
      border-radius: 100px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:focus {
      outline: none;
    }
  }
  &.found {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    button::before {
      opacity: 1;
    }
  }
}

</style>