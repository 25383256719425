export default [
  {
    name: `splash_screen`,
    startCTA: `スタートする`,
  },
  {
    name: `onboarding_screen`,
    baseTutorial: [
      {
        name: `360_visit`,
        text: `ヴァン クリーフ＆アーペルのハイジュエリーのアトリエを360度ツアーする`,
      },
      {
        name: `movement`,
        text: `地面の矢印を使って移動してください`,
      },
      {
        name: `hotspots`,
        text: `さらに詳しくは円をクリックしてください`,
      },
    ],
    gameTutorial: `ツアー中、宝石を集めてください`,
    // browserTip: `より快適にご利用いただくために、Google Chromeブラウザのご利用をお勧めいたします。`,
    startCTA: `続ける`,
  }
];
