export default [
  {
    name: `place_vendome_1`,
    tex: require(`@/assets/360/place_vendome_1_mobile.jpg`),
  },
  {
    name: `place_vendome_2`,
    tex: require(`@/assets/360/place_vendome_2_mobile.jpg`),
  },
  {
    name: `maquettes_etain`,
    tex: require(`@/assets/360/maquettes_etain_mobile.jpg`),
  },
  {
    name: `entree_ateliers`,
    tex: require(`@/assets/360/entree_ateliers_mobile.jpg`),
  },
  {
    name: `polisseur`,
    tex: require(`@/assets/360/polisseur_mobile.jpg`),
  },
  {
    name: `joaillier_1`,
    tex: require(`@/assets/360/joaillier_1_mobile.jpg`),
  },
  {
    name: `joaillier_2`,
    tex: require(`@/assets/360/joaillier_2_mobile.jpg`),
  },
  {
    name: `sertisseur`,
    tex: require(`@/assets/360/sertisseur_mobile.jpg`),
  },
  {
    name: `fondeur`,
    tex: require(`@/assets/360/fondeur_mobile.jpg`),
  },
  {
    name: `diamantaire`,
    tex: require(`@/assets/360/diamantaire_mobile.jpg`),
  },
  {
    name: `lapidaire_1`,
    tex: require(`@/assets/360/lapidaire_1_mobile.jpg`),
  },
  {
    name: `lapidaire_2`,
    tex: require(`@/assets/360/lapidaire_2_mobile.jpg`),
  },
  {
    name: `concepteur_cao`,
    tex: require(`@/assets/360/concepteur_cao_mobile.jpg`),
  },
];