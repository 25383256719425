<template>
  <div
    class="vca-tutorial"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <h2 class="tutorial-title">{{ config.title }}</h2>
    <div class="tutorial-elements-wrapper">
      <div
        :id="`tutorial-element-${idx}`"
        class="tutorial-element"
        :class="`${idx !== 0 ? `` : ` tutorial-element-visible`}`"
        v-for="(elem, idx) in config.tutorialElements"
        :key="`tuto-${elem.name}`"
      >
        <video
          class="element-video"
          :autoplay="true"
          :playsinline="true"
          :webkit-playsinline="true"
          :muted="true"
          :default-muted="true"
          :loop="true"
          v-if="renderVideos && !$breakpoints.isMobile"
        >
          <source :src="elem.video" type="video/mp4" />
        </video>
        <img :src="elem.gif" alt="tutorial-gif" class="element-video" v-else />
        <p class="element-text">{{ elem.text }}</p>
      </div>
    </div>
    <a class="close-tutorial-cta" @click.prevent="close">{{ config.continueCTA }}</a>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    renderVideos: true,
  }),

  mounted() {
  },

  methods: {
    close() {
      this.$emit(`close`);
    }
  },

  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.renderVideos = false;
        }, 500);
      } else {
        const tuto1 = document.getElementById(`tutorial-element-1`);
        const tuto2 = document.getElementById(`tutorial-element-2`);

        setTimeout(() => {
          tuto1.classList.add(`tutorial-element-visible`);
        }, 2000);
        setTimeout(() => {
          tuto2.classList.add(`tutorial-element-visible`);
        }, 4000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vca-tutorial {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  min-width: 700px;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .tutorial-title {
    font-size: 1.5em;
    font-weight: 700;
  }

  .tutorial-elements-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .tutorial-element {
      width: 30%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.tutorial-element-visible {
        opacity: 1;
      }

      .element-video {
        width: 100%;
      }

      .element-text {
        margin: 3em 0;
        font-size: 13px;
      }
    }
  }

  .close-tutorial-cta {
    margin-top: 3em;
    padding: 0.75em 2.5em;
    border-radius: 6em;
    border: 1px solid #4bb8d9;
    background-color: #ffffff;
    color: #4bb8d9;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      background-color: #4bb8d9;
      color: #ffffff;
    }
  }

  &.mobile {
    width: auto;
    min-width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0,0);

    .tutorial-elements-wrapper {
      flex-direction: column;
      flex-grow: 1;

      .tutorial-element {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30%;

        .element-video {
          width: 50%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .element-text {
          margin: 0;
          padding: 1em;
        }
      }
    }
  }
}
</style>