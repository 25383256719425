export default {
  name: `game`,
  gemFound: {
    congrats: `CONGRATULATIONS !`,
    foundMessage: `YOU HAVE COLLECTED A NEW GEMSTONE!`,
    continueCTA: `CONTINUE`,
  },
  gameWon: {
    congrats: `CONGRATULATIONS`,
    gemsFound: `YOU FOUND ALL THE GEMSTONES! `,
    continueCTA:  `CONTINUE`,
    startOverCTA: `START THE TOUR OVER`,
  },
  gemsHover: `COLLECT THE GEMSTONES`,
}
