import * as THREE from 'three'
import EvEmitter from './EvEmitter'
import Mats from './Mats'

export default class Spots extends EvEmitter {
  constructor(ap3, spotsConfig, assets, events) {
    super()
    this.init(ap3, spotsConfig)
    this.setEventListeners(events)
    this.setMats(assets.mats, assets.texs)
    this.generateSpot2d()
    this.generateSpot3d()
  }

  init(ap3, spotsConfig) {
    this.ap3 = ap3
    this.ap3.cam.addTicks(this.tickTrack.bind(this))
    this.params = {
      opacityArrow: { normal: 0.7, hover: 1 },
      durationFadeIn: 0.5,
      durationFadeOut: 0.333
    }
    this.spots2dConfig = spotsConfig.spots2D
    this.spots3dConfig = spotsConfig.spots3D
    this.spots2d = []
    this.spots3d = []
    this.spots2dList = []
    this.spots3dList = []
    this.spotsAllElements = []
    this.spots3dUsage = {}

    this.fading = []
    this.fadingData = {}
    this.circleGeo = new THREE.CircleGeometry(0.5, 16)
    this.squareGeo = new THREE.PlaneGeometry()
    this.circleGeo.name = 'circleGeo'
    this.squareGeo.name = 'squareGeo'
    this.vec3A = new THREE.Vector3()
    this.vec3B = new THREE.Vector3()
    this.isInFront = true
  }

  setMats(mats, texs) {
    this.matsSpots = new Mats(mats, texs)
    for(const mat in this.matsSpots.mats) {
      this.matsSpots.mats[mat].opacity = this.params.opacityArrow.normal
      this.matsSpots.mats[mat].depthWrite = false
    }
  }

  setEventListeners(events) {
    for (const evt of events.events2D) {
      this.on(evt.type, evt.cb)
    }
    for (const evt of events.events3D) {
      this.ap3.ray.addEventListener(evt)
    }
  }

  generateSpot2d() {
    for(let spotData of this.spots2dConfig) {
      const ucName = spotData.name.charAt(0).toUpperCase() + spotData.name.slice(1)

      this[`hLon${ucName}`] = new THREE.Object3D()
      this[`hAzi${ucName}`] = new THREE.Object3D()
      this[`h${ucName}`] = new THREE.Object3D()

      this[`hLon${ucName}`].name = `hLon${ucName}`
      this[`hAzi${ucName}`].name = `hAzi${ucName}`
      this[`h${ucName}`].name = spotData.name
      this[`h${ucName}`].sceneIndex = spotData.$sceneIndex

      this[`hLon${ucName}`].rotation.y = - (spotData.longitude + 90) * Math.PI/180
      this[`hAzi${ucName}`].rotation.x = - spotData.azimuth * Math.PI/180
      this[`h${ucName}`].position.z = 10

      this.ap3.scene.add(this[`hLon${ucName}`])
      this[`hLon${ucName}`].add(this[`hAzi${ucName}`])
      this[`hAzi${ucName}`].add(this[`h${ucName}`])

      this.spots2d.push(this[`h${ucName}`])
      this.spots2dList.push(this[`h${ucName}`].name)
      this.spotsAllElements.push(this[`hLon${ucName}`], this[`hAzi${ucName}`], this[`h${ucName}`])
    }
  }

  generateSpot3d() {
    const visitProgression = JSON.parse(localStorage.getItem(`vcaVisitedScenes`));

    for(let spotData of this.spots3dConfig) {
      const ucName = spotData.name.charAt(0).toUpperCase() + spotData.name.slice(1)

      this[`hLon${ucName}`] = new THREE.Object3D()
      this[`hAzi${ucName}`] = new THREE.Object3D()
      this[`hIncli${ucName}`] = new THREE.Object3D()
      this[spotData.name] = new THREE.Mesh(this.squareGeo, this.matsSpots.mats[spotData.image])
      this[spotData.name] = new THREE.Mesh(this.squareGeo)
      if(!this.spots3dUsage[spotData.image]) {
        if (visitProgression[spotData.action.args.scene] === true) {
          this[spotData.name].material = this.matsSpots.mats[spotData.image]
        } else {
          this[spotData.name].material = this.matsSpots.mats[`arrow_0H`]
        }
        this[spotData.name].material.opacity = this.params.opacityArrow.normal
        this.spots3dUsage[spotData.image] = 1
      } else {
        this.matsSpots.mats[`${spotData.image}_${`0${this.spots3dUsage[spotData.image]}`.slice(-2)}`] = new THREE.MeshBasicMaterial()
        this.matsSpots.mats[`${spotData.image}_${`0${this.spots3dUsage[spotData.image]}`.slice(-2)}`].copy(this.matsSpots.mats[spotData.image])
        if (visitProgression[spotData.action.args.scene] === true) {
          this[spotData.name].material = this.matsSpots.mats[`${spotData.image}_${`0${this.spots3dUsage[spotData.image]}`.slice(-2)}`]
        } else {
          this[spotData.name].material = this.matsSpots.mats[`arrow_0H`]
        }
        this[spotData.name].material.opacity = this.params.opacityArrow.normal
        this.spots3dUsage[spotData.image] += 1
      }
      this[`click${ucName}`] = new THREE.Mesh(this[`${spotData.clickShape}Geo`])

      this[`hLon${ucName}`].name = `hLon${ucName}`
      this[`hAzi${ucName}`].name = `hAzi${ucName}`
      this[`hIncli${ucName}`].name = `hIncli${ucName}`
      this[spotData.name].name = spotData.name
      this[spotData.name].sceneIndex = spotData.$sceneIndex
      this[`click${ucName}`].name = `click${ucName}`
      this[spotData.name].action = spotData.action

      this[`hIncli${ucName}`].scale.set( spotData.size, spotData.size, spotData.size )
      this[`hIncli${ucName}`].position.z = 10 - Math.pow((2 * Math.pow(spotData.size/2, 2)), 0.5)
      this[`hLon${ucName}`].rotation.y = - (spotData.longitude + 90) * Math.PI/180
      this[`hAzi${ucName}`].rotation.x = - spotData.azimuth * Math.PI/180
      this[`hIncli${ucName}`].rotation.x = (180 + spotData.inclinationX) * Math.PI/180
      this[`hIncli${ucName}`].rotation.y = (spotData.inclinationY) * Math.PI/180
      this[`hIncli${ucName}`].rotation.z = (180 - spotData.direction) * Math.PI/180
      this[`click${ucName}`].position.x = spotData.clickOffsetX/2
      this[`click${ucName}`].position.y = spotData.clickOffsetY/2
      this[`click${ucName}`].position.z = 0.001
      this[`click${ucName}`].scale.set(spotData.clickScaleX, spotData.clickScaleY, 1)

      this.ap3.scene.add(this[`hLon${ucName}`])
      this[`hLon${ucName}`].add(this[`hAzi${ucName}`])
      this[`hAzi${ucName}`].add(this[`hIncli${ucName}`])
      this[`hIncli${ucName}`].add(this[spotData.name])
      this[`hIncli${ucName}`].add(this[`click${ucName}`])

      this[`click${ucName}`].visible = false
      this.spots3d.push(this[spotData.name])
      this.spots3dList.push(this[spotData.name].name)
      this.spotsAllElements.push(this[`hLon${ucName}`], this[`hAzi${ucName}`], this[`hIncli${ucName}`], this[spotData.name], this[`click${ucName}`])
      this.ap3.ray.castList.push(this[`click${ucName}`])
    }
  }

  fade(object, direction) {
    if(!this.fading.includes(object)) { this.fading.push(object) }
    this.fadingData[object.name] = { iniOpacity: object.material.opacity, startTime: this.ap3.time.elapsed }
    if(direction == 'in') {
      this.fadingData[object.name].targetOpacity = this.params.opacityArrow.hover
      this.fadingData[object.name].durationFade = this.params.durationFadeIn * 1000
    } else {
      this.fadingData[object.name].targetOpacity = this.params.opacityArrow.normal
      this.fadingData[object.name].durationFade = this.params.durationFadeOut * 1000
    }
    this.ap3.time.addEvent('fading')
  }

  tickFade() {
    for(const o of this.fading) {
      const taux = (this.ap3.time.elapsed - this.fadingData[o.name].startTime) / this.fadingData[o.name].durationFade
      o.material.opacity = THREE.MathUtils.lerp(this.fadingData[o.name].iniOpacity, this.fadingData[o.name].targetOpacity, taux)
      if(taux > 1) {
        o.material.opacity = this.fadingData[o.name].targetOpacity
        this.fading = this.fading.filter(e => e != o)
      }
    }
    if(!this.fading.length) { this.ap3.time.remEvent('fading') }
  }

  tickTrack() {
    for(const spot2d of this.spots2d) {
      spot2d.getWorldPosition(this.vec3A)
      this.ap3.cam.cam.getWorldDirection(this.vec3B)
      this.isInFront = (this.vec3A.angleTo(this.vec3B) < Math.PI/2)
      this.vec3A.project(this.ap3.cam.cam)
      this.trigger(`spot_2d_position`, [spot2d.name, spot2d.sceneIndex, this.isInFront, {x: this.vec3A.x, y: this.vec3A.y}])
    }

    for (const spot3d of this.spots3d) {
      spot3d.getWorldPosition(this.vec3A)
      this.ap3.cam.cam.getWorldDirection(this.vec3B)
      this.isInFront = (this.vec3A.angleTo(this.vec3B) < Math.PI/2)
      this.vec3A.project(this.ap3.cam.cam)
      this.trigger(`spot_3d_position`, [spot3d.name, spot3d.sceneIndex, this.isInFront, {x: this.vec3A.x, y: this.vec3A.y}])
    }
  }

  disposeSpots() {
    for (let i = 0; i < this.spots2d.length; i++) {
      const ucName = this.spots2d[i].name.charAt(0).toUpperCase() + this.spots2d[i].name.slice(1)

      this[`hLon${ucName}`].removeFromParent()
      this[`hAzi${ucName}`].removeFromParent()
      this[`h${ucName}`].removeFromParent()

      delete this[`hLon${ucName}`]
      delete this[`hAzi${ucName}`]
      delete this[`h${ucName}`]
    }
    this.spots2d = []
    this.spots2dList = []

    for (let i = 0; i < this.spots3d.length; i++) {
      const ucName = this.spots3d[i].name.charAt(0).toUpperCase() + this.spots3d[i].name.slice(1)

      this[`hLon${ucName}`].removeFromParent()
      this[`hAzi${ucName}`].removeFromParent()
      this[`hIncli${ucName}`].removeFromParent()
      this[`click${ucName}`].removeFromParent()
      this[this.spots3d[i].name].removeFromParent()

      this.ap3.ray.removeCastElement(this.spots3d[i].name)

      delete this[`hLon${ucName}`]
      delete this[`hAzi${ucName}`]
      delete this[`hIncli${ucName}`]
      delete this[`click${ucName}`]
      delete this[this.spots3d[i].name]
    }
    this.spots3d = []
    this.spots3dList = []

    this.spotsAllElements = []
  }

  setNewSceneSpots(spotsConfig) {
    this.spots2dConfig = spotsConfig.spots2D
    this.spots3dConfig = spotsConfig.spots3D

    this.generateSpot2d()
    this.generateSpot3d()
  }

  destroy() {
    for(let el of this.spotsAllElements) {
      if(el.isMesh) {
        el.geometry.dispose()
      }
    }
    for(let mat in this.matsSpots.mats) {
      this.matsSpots.mats[mat].map.dispose()
      this.matsSpots.mats[mat].dispose()
    }
  }
}