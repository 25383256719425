export default {
  name: `menu`,
  entries: [
    {
      name: `carte_ateliers`,
      text: `Workshop floor plan`,
    },
    {
      name: `toggle_fullscreen`,
      text: `Full screen mode`,
    },
  ]
}