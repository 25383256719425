<template>
  <hotspot-ui
    class="vca-hotspot"
    :class="{
      visited,
    }"
    :spot-config="spotConfig"
    :action="action"
    v-on="{ [action.type]: execAction }"
  >
    <div class="vca-hotspot-inner">{{ category === `number` ? idx : `` }}</div>
  </hotspot-ui>
</template>

<script>
import HotspotUi from '@/components/HotspotUi/HotspotUi'

export default {
  components: {
    HotspotUi,
  },

  props: {
    spotConfig: {
      type: Object,
      required: true,
    },
    idx: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    visited: false,
  }),

  computed: {
    category() {
      return this.spotConfig?.category || `basic`;
    },
    action() {
      return this.spotConfig?.action || { type: ``, args: {} };
    },
    backgroundColor() {
      return this.spotConfig?.backgroundColor || '#ffffff';
    }
  },

  mounted() {
    this.visited = localStorage.getItem(`${this.spotConfig.name}-visited`) === 'true'
  },

  methods: {
    execAction() {
      if (this.visited === false) {
        localStorage.setItem(`${this.spotConfig.name}-visited`, true);
        this.visited = localStorage.getItem(`${this.spotConfig.name}-visited`) === 'true';
      }

      this.$emit(`click`, { ...this.action, idx: this.idx });
    }
  },
}
</script>

<style lang="scss">

.vca-hotspot {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  button {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #4ba8cb;
    border-radius: 100px;

    &::before {
      content: '';
      z-index: 0;
      display: block;
      position: absolute;
      width: 25%;
      height: 25%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
      background-color: #ffffff;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
      border: 1px solid #ffffff;
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    }

    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;

    .vca-hotspot-inner {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #4ba8cb;
      font-weight: 700;
    }

    &:focus {
      outline: none;
    }
  }

  &.visited {
    button {
      background-color: rgba(106, 133, 149, 0.6);

      .vca-hotspot-inner {
        color: rgb(106, 133, 149);
      }
    }
  }

  &:hover {
    button {
      width: 2.3em;
      height: 2.3em;

      &::after {
        width: 20%;
        height: 20%;
      }
    }
  }
}

</style>